import { Flex, useToken } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

type QuotientIllustrationProps = {
  lightIcon: IconDefinition;
  solidIcon: IconDefinition;
  size?: SizeProp;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientIllustration = ({ lightIcon, solidIcon, size = '9x', ...rest }: QuotientIllustrationProps) => {
  const [surfaceBrandActiveLight, surfaceBrandDefault] = useToken('colors', [
    'surfaceBrandActiveLight',
    'surfaceBrandDefault',
  ]);
  return (
    <Flex
      alignItems="center"
      className={`fa-layers fa-fw fa-${size}`}
      flexDirection="column"
      gap={3}
      marginTop={6}
      {...rest}
    >
      <FontAwesomeIcon color={surfaceBrandActiveLight} icon={solidIcon} />
      <FontAwesomeIcon color={surfaceBrandDefault} icon={lightIcon} transform="up-1 right-1" />
    </Flex>
  );
};
