import type { ComponentStyleConfig } from 'quotient';

export const Link: ComponentStyleConfig = {
  baseStyle: {
    color: 'textLink',
    _hover: {
      textDecoration: 'none',
      color: 'textBrandActive',
    },
  },
};
