import { Center, forwardRef } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEventHandler } from 'react';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

type IconSize = 'xs' | 'sm' | 'lg';

type IconProps = {
  size?: IconSize;
  color?: string;
  icon: IconDefinition;
  onClick?: MouseEventHandler;
  fixedWidth?: boolean;
  pointer?: boolean;
  swapOpacity?: boolean;
} & SelectedStyledSystemProps &
  CommonCIQProps;

const IconSizeMapper: { [key in IconSize]: SizeProp } = {
  xs: 'xs',
  sm: '1x',
  lg: '2x',
};

const IconDimensionMapper: { [key in IconSize]: string } = {
  xs: '10px',
  sm: '16px',
  lg: '32px',
};

export const QuotientIcon = forwardRef<IconProps, 'div'>(
  ({ size = 'sm', color, icon, fixedWidth, onClick, pointer, 'data-testid': dataTestId, ...rest }: IconProps, ref) => {
    const dimension = IconDimensionMapper[size];
    return (
      <Center
        color={color}
        cursor={pointer ? 'pointer' : undefined}
        display="inline-flex"
        height={dimension}
        ref={ref}
        width={dimension}
        {...rest}
      >
        <FontAwesomeIcon
          data-testid={dataTestId}
          fixedWidth={fixedWidth}
          icon={icon}
          size={IconSizeMapper[size]}
          onClick={onClick}
        />
      </Center>
    );
  },
);
