import { useQueryClient, useQuery, useMutation, UseMutationOptions, UseQueryOptions } from 'react-query';

import { deleteRequest, getRequest, postRequest, putRequest } from 'services/apiRequests';
import type { APIList, StringifiedUUID } from 'types';
import {
  PlanningCycle,
  PlanningCycleRequest,
  PlanningCycleSnapshotUpsertRequest,
  PlanningCycleSnapshotUpsertResponse,
} from 'types/planningCycles';
import type { APIError } from 'utils/errors';

const apiUrl = '/api/v1/tq/planning-cycles/';

const getPlanningCycle = (planningCycleId: StringifiedUUID) =>
  getRequest<null, PlanningCycle>(`${apiUrl}${planningCycleId}/`);

const useGetPlanningCyclesQuery = (planningCycleId: StringifiedUUID) => {
  const QUERY_KEY = ['planning-cycles', planningCycleId];
  const queryClient = useQueryClient();

  const query = useQuery<PlanningCycle, APIError>(QUERY_KEY, () => getPlanningCycle(planningCycleId));
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const listPlanningCycles = () => getRequest<null, APIList<PlanningCycle>>(apiUrl);

const useListPlanningCyclesQuery = (
  options: UseQueryOptions<APIList<PlanningCycle>, APIError, PlanningCycle[]> = {},
) => {
  const QUERY_KEY = ['planning-cycles', 'list'];
  const queryClient = useQueryClient();
  const query = useQuery<APIList<PlanningCycle>, APIError, PlanningCycle[]>(QUERY_KEY, () => listPlanningCycles(), {
    select: (response) => response.data || [],
    ...options,
  });
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const createPlanningCycle = (csrfToken: string) => (planningCycle: PlanningCycleRequest) => {
  return postRequest<PlanningCycleRequest, PlanningCycle>(apiUrl, planningCycle, csrfToken);
};

const useCreatePlanningCycleMutation = (
  csrfToken: string,
  options: UseMutationOptions<PlanningCycle, APIError, PlanningCycleRequest> = {},
) => {
  return {
    mutation: useMutation<PlanningCycle, APIError, PlanningCycleRequest>(createPlanningCycle(csrfToken), options),
  };
};

// can we consider not using strict serializer on backend here to save having to split up the payload
type UpdatePlanningCycleProps = {
  planningCycleId: StringifiedUUID;
  planningCycle: PlanningCycleRequest;
};

const updatePlanningCycle = (csrfToken: string) => ({ planningCycleId, planningCycle }: UpdatePlanningCycleProps) => {
  return putRequest<PlanningCycleRequest, PlanningCycle>(`${apiUrl}${planningCycleId}/`, planningCycle, csrfToken);
};

const useUpdatePlanningCycleMutation = (
  csrfToken: string,
  options: UseMutationOptions<PlanningCycle, APIError, UpdatePlanningCycleProps> = {},
) => {
  return {
    mutation: useMutation<PlanningCycle, APIError, UpdatePlanningCycleProps>(updatePlanningCycle(csrfToken), options),
  };
};

const deletePlanningCycle = (csrfToken: string) => (planningCycleId: StringifiedUUID) => {
  return deleteRequest<null, PlanningCycle>(`${apiUrl}${planningCycleId}/`, null, csrfToken);
};

const useDeletePlanningCycleMutation = (
  csrfToken: string,
  options: UseMutationOptions<PlanningCycle, APIError, StringifiedUUID> = {},
) => {
  return {
    mutation: useMutation<PlanningCycle, APIError, StringifiedUUID>(deletePlanningCycle(csrfToken), options),
  };
};

const upsertDataSnapshotReference = (
  csrfToken: string,
  planningCycleId: StringifiedUUID,
  payload: PlanningCycleSnapshotUpsertRequest,
) => {
  return postRequest<PlanningCycleSnapshotUpsertRequest, PlanningCycleSnapshotUpsertResponse>(
    `${apiUrl}${planningCycleId}/data-snapshot-reference/`,
    payload,
    csrfToken,
  );
};

export const PlanningCyclesService = {
  apiUrl,
  useGetPlanningCyclesQuery,
  useListPlanningCyclesQuery,
  useCreatePlanningCycleMutation,
  useUpdatePlanningCycleMutation,
  useDeletePlanningCycleMutation,
  upsertDataSnapshotReference,
};
