import { faSearch } from '@fortawesome/pro-regular-svg-icons/faSearch';
import { KBarProvider, KBarPortal, KBarPositioner, KBarAnimator, KBarSearch, KBarOptions, ActionImpl } from 'kbar';
import debounce from 'lodash/debounce';
import { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import { ChakraWrapper, chakra, Icon, Box } from 'quotient';
import { Events } from 'quotient/utils/events';
import { isSpaEnabled, isFeatureEnabled } from 'quotient/utils/utils';

import { CommandBarFooter } from './CommandBarFooter';
import { CommandBarResults } from './CommandBarResults';
import { CommandBarBaseAction, CommandBarCustomHeaderIconMap } from './types';

const MODAL_Z_INDEX = 1050;

const debouncedQueryChangeTrack = debounce((searchQuery) => {
  Events.track(Events.EVENT.command_bar.modal.query_changed, { query: searchQuery });
}, 1000);

export const CommandBarProvider = ({
  staticActionItems,
  children,
}: {
  staticActionItems: CommandBarBaseAction<string, string>[];
  children: ReactNode;
}) => {
  const history = useHistory();
  const options: KBarOptions = {
    disableScrollbarManagement: true,
    enableHistory: true,
    toggleShortcut: '',
    callbacks: {
      onOpen: () => {
        Events.track(Events.EVENT.command_bar.modal.hotkey_opened, {});
      },
      onClose: () => {
        Events.track(Events.EVENT.command_bar.modal.closed, {});
      },
      onQueryChange: (searchQuery: string) => {
        debouncedQueryChangeTrack(searchQuery);
      },
      onSelectAction: (action: ActionImpl) => {
        Events.track(Events.EVENT.command_bar.modal.action_selected, { query: action.id });
      },
    },
  };

  // this converts our actions.ts struct into a format for kbar
  // mainly does feature flag detection and wraps spa behavior and event tracking
  const kbarActions = staticActionItems
    .filter((action) => {
      if (action.featureFlagCallback) {
        return action.featureFlagCallback(isFeatureEnabled);
      }
      return true;
    })
    .map((action) => {
      const wrappedPerform = action.perform
        ? action.perform
        : () => {
            if (isSpaEnabled()) {
              history.push(action.url);
            } else {
              window.location.pathname = action.url;
            }
          };
      return {
        ...action,
        perform: wrappedPerform,
      };
    });

  return (
    <KBarProvider actions={kbarActions as any} options={options}>
      {children}
    </KBarProvider>
  );
};

type CommandBarProps = {
  resultHeaderIconMap?: CommandBarCustomHeaderIconMap;
};

const ChakraKBarPositioner = chakra(KBarPositioner);
const ChakraKBarAnimator = chakra(KBarAnimator);
const ChakraKBarSearch = chakra(KBarSearch);

export const CommandBar: React.VFC<CommandBarProps> = ({ resultHeaderIconMap }) => {
  return (
    <>
      <KBarPortal>
        <ChakraWrapper isEnabled>
          <ChakraKBarPositioner bgColor="rgba(0, 0, 0, 0.3)" zIndex={MODAL_Z_INDEX}>
            <ChakraKBarAnimator
              bgColor="surfaceBackgroundHighContrast"
              borderRadius="0.3rem"
              maxW="600px"
              overflow="hidden"
              shadow="card"
              w="100%"
            >
              <Box color="primaryNeutral.800" h="24px" left={4} position="absolute" top={3.5} w="24px">
                <Icon icon={faSearch} size="lg" />
              </Box>
              <ChakraKBarSearch
                apply="borders.lightBg"
                boxSizing="border-box"
                fontSize="lg"
                lineHeight="md"
                outline="none"
                pb={3}
                pl={14}
                pr={4}
                pt={3}
                w="100%"
              />
              <CommandBarResults resultHeaderIconMap={resultHeaderIconMap ?? {}} />
              <CommandBarFooter />
            </ChakraKBarAnimator>
          </ChakraKBarPositioner>
        </ChakraWrapper>
      </KBarPortal>
    </>
  );
};
