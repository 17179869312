export const focusStyling = {
  boxShadow: 'none',
  outlineColor: 'primary.300',
  outlineWidth: '2px',
  outlineStyle: 'solid',
};

export const labelStyling = {
  color: 'textHighContrast',
  textStyle: 'bodyRegular',
  _disabled: {
    color: 'textLowContrast',
  },
};

export const inputDefaultStyling = {
  backgroundColor: 'surfaceBackgroundHighContrast',
  borderColor: 'textLowContrast',
  borderWidth: '1px',
};

export const inputDisabledStyling = {
  bg: 'surfaceBackgroundHighContrast',
  borderColor: 'textLowContrast',
};

export const inputCheckedStyling = {
  backgroundColor: 'surfaceBrandDefault',
  borderColor: 'surfaceBrandDefault',
};

export const inputCheckboxDisabledStyling = {
  backgroundColor: 'surfaceBrandDisabled',
  borderColor: 'surfaceBrandDisabled',
  color: 'surfaceBackgroundHighContrast',
};
