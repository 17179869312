export const getElementText = (element: any): string => {
  if (['string', 'number'].includes(typeof element)) return `${element}`.trim();
  if (element instanceof Array) return element.map(getElementText).join(' ').trim();
  if (typeof element === 'object' && element) return getElementText(element.props?.children ?? []).trim();
  return '';
};

// temporary while we find a better home
const SPA_FEATURE_FLAG_NAME = 'feature_flag_spa';

export const isFeatureEnabled = (featureFlag: string): boolean => {
  const featureFlags: string[] = window.ciq?.enabledFeatureFlags || [];
  return featureFlags.includes(featureFlag);
};

export const isSpaEnabled = (): boolean => isFeatureEnabled(SPA_FEATURE_FLAG_NAME);
