type ReferToDocumentation = 'Please refer to the Frontend Tracking document: https://www.notion.so/captivateiq/RFC-Frontend-Event-Tracking-ba97572a638c413cb0f070bc27a63d6f';

type LowerCaseLetter =
  | 'a'
  | 'b'
  | 'c'
  | 'd'
  | 'e'
  | 'f'
  | 'g'
  | 'h'
  | 'i'
  | 'j'
  | 'k'
  | 'l'
  | 'm'
  | 'n'
  | 'o'
  | 'p'
  | 'q'
  | 'r'
  | 's'
  | 't'
  | 'u'
  | 'v'
  | 'w'
  | 'x'
  | 'y'
  | 'z';

/**
 * The difference between TypeScript's built-in Lowercase<StringType> and this
 * type is that Lowercase is a type-level string transformation whereas this one
 * represents the set of string values containing only lowercase letters.
 */
type LowerCaseString<T> = T extends `${infer Head}${infer Tail}`
  ? Head extends LowerCaseLetter
    ? Tail extends LowerCaseString<Tail>
      ? T
      : never
    : never
  : T;

type NonEmptyString<T> = T extends '' ? never : T;

/**
 * Represents a snake_cased string with lowercase letters.
 * Does not permit leading or trailing underscores.
 */
type SnakeCaseString<T> = T extends `${infer Head}_${infer Tail}`
  ? `${LowerCaseString<NonEmptyString<Head>>}_${SnakeCaseString<Tail>}`
  : LowerCaseString<NonEmptyString<T>>;

export type EventName<T> = T extends string
  ? T extends `${infer Context}__${infer Object}__${infer Action}`
    ? Context extends SnakeCaseString<Context>
      ? Object extends SnakeCaseString<Object>
        ? Action extends SnakeCaseString<Action>
          ? T
          : `The event action is malformed. ${ReferToDocumentation}`
        : `The event object is malformed. ${ReferToDocumentation}`
      : `The event context is malformed. ${ReferToDocumentation}`
    : `The event name is missing delimiters. ${ReferToDocumentation}`
  : `The event name must be a string. ${ReferToDocumentation}`;

export type EventPropertyValue = string | number | boolean | undefined;

type RecordWithSnakeCaseKeys<R> = R extends Record<string, any>
  ? keyof R extends SnakeCaseString<keyof R>
    ? R
    : never
  : never;

export type EventData<R> = R extends Record<string, EventPropertyValue>
  ? R extends RecordWithSnakeCaseKeys<R>
    ? R
    : `One or more property keys are not snake_cased with lowercase letters. ${ReferToDocumentation}`
  : `Event data must be a flattened object type. ${ReferToDocumentation}`;

/*  note for other devs:
 *  IP Address automatically gets collected by segment, the recommended way to anonymize here is like such:
 *  https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/identity/#anonymizing-ip
 *
 *  make sure this anonymous context is used for all segment.io calls
 */
const ANONYMOUS_CONTEXT = { context: { ip: '0.0.0.0' } };
const CACHE_EXPIRY_MILLISECONDS = 500;

export class CaptivateIQEvents {
  static cache: Map<string, boolean> = new Map();

  // Useful when tracking something inside a react render cycle
  static dedupeTrack<T, U>(name: EventName<T>, data: EventData<U>) {
    const cacheKey = `${name}.${JSON.stringify(data)}`;
    if (this.cache.get(cacheKey)) {
      return;
    }

    this.cache.set(cacheKey, true);
    this.track(name, data);
    setTimeout(() => {
      this.cache.delete(cacheKey);
    }, CACHE_EXPIRY_MILLISECONDS);
  }

  static track<T, U>(name: EventName<T>, data: EventData<U>) {
    window.DD_RUM?.addAction(name, data);
    window.analytics?.track(name, data, ANONYMOUS_CONTEXT);
    if (window.pendo?.isReady && window.pendo?.isReady()) {
      window.pendo.track(name, data);
    }

    if (window.location.hostname === 'localhost' && window.localStorage.getItem('localEventTracking')) {
      // eslint-disable-next-line no-console
      console.log(`%cPendo Event: ${name}`, 'font-weight: bold; color: rgb(253, 63, 107);');
      // eslint-disable-next-line no-console
      console.table(data);
    }
  }

  static page<U>(data: EventData<U>) {
    // null for the first param so it auto-populates
    window.analytics?.page(null, data, ANONYMOUS_CONTEXT);
  }
}
