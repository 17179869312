import { useQuery, useQueryClient } from 'react-query';
import type { UseQueryOptions } from 'react-query';

import type { User, AccessorRole } from 'types';
import type { Permission } from 'types/permissions';
import type { APIError } from 'utils/errors';

import { getRequest } from './apiRequests';

export type GetCurrentUserResponse = User & { organization_name: string };
export type ListUsersResponse = {
  object: 'list';
  data: User[];
  total_count: number;
};

export type GetCurrentUserPermissionsResponse = {
  shared_resources: Array<
    Permission & {
      created_at: string;
      updated_at: string;
      id: string;
      object: 'shared_resource';
    }
  >;
  role: AccessorRole;
  can_user_be_assigned_role: boolean;
};

const getCurrentUserUrl = '/api/payee/profile';
const getCurrentUser = () => getRequest<null, GetCurrentUserResponse>(getCurrentUserUrl);

const getUsersUrl = (searchQuery: string) => `/api/users?searchQuery=${searchQuery}`;
const getUsers = (searchQuery: string) => getRequest<null, ListUsersResponse>(getUsersUrl(searchQuery));

const useGetCurrentUserQuery = (options: UseQueryOptions<GetCurrentUserResponse, APIError> = {}) => {
  return {
    query: useQuery<GetCurrentUserResponse, APIError>('currentUser', () => getCurrentUser(), {
      ...options,
    }),
  };
};

const getCurrentUserPermissions = () => getRequest<null, GetCurrentUserPermissionsResponse>(`/api/profile/permissions`);

const useGetCurrentUserPermissions = (options: UseQueryOptions<GetCurrentUserPermissionsResponse, APIError> = {}) => {
  return {
    query: useQuery<GetCurrentUserPermissionsResponse, APIError>(
      ['currentUserPermissions'],
      getCurrentUserPermissions,
      options,
    ),
  };
};

const useGetUsersQuery = (searchQuery: string, enabled: boolean) => {
  const QUERY_KEY = ['users', searchQuery];
  const queryClient = useQueryClient();

  const query = useQuery<ListUsersResponse, APIError>({
    queryKey: QUERY_KEY,
    queryFn: () => getUsers(searchQuery),
    enabled,
  });
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

export const UsersService = {
  useGetCurrentUserQuery,
  useGetUsersQuery,
  useGetCurrentUserPermissions,
};
