// copied from ICM
import { ValueOf } from 'type-fest';

export type ResourceTypes = 'workbook' | 'worksheet' | 'commission_plan' | 'period_group';

export type PermissionTypes = 'manage' | 'create' | 'update' | 'read';

export const WORKBOOK_ACTIONS = {
  WORKBOOK_SEE_WORKSHEETS: 'WORKBOOK_SEE_WORKSHEETS',
  WORKBOOK_DELETE: 'WORKBOOK_DELETE',
  WORKBOOK_EDIT: 'WORKBOOK_EDIT',
  WORKBOOK_CHANGE_ACTIVE: 'WORKBOOK_CHANGE_ACTIVE',
  WORKBOOK_CLONE: 'WORKBOOK_CLONE',
  WORKBOOK_CHANGE_VISIBILITY: 'WORKBOOK_CHANGE_VISIBILITY',
  WORKBOOK_SWITCH_WORKSHEETS: 'WORKBOOK_SWITCH_WORKSHEETS',
  WORKBOOK_ADD_WORKSHEET: 'WORKBOOK_ADD_WORKSHEET',
  WORKBOOK_CLONE_WORKSHEET: 'WORKBOOK_CLONE_WORKSHEET',
  WORKBOOK_SORT_WORKSHEET_TABS: 'WORKBOOK_SORT_WORKSHEET_TABS',
  WORKBOOK_VIEW_ALL_WORKSHEET_TABS: 'WORKBOOK_VIEW_ALL_WORKSHEET_TABS',
  WORKBOOK_CLICK_BACK_INTO_WORKBOOKS_SECTION: 'WORKBOOK_CLICK_BACK_INTO_WORKBOOKS_SECTION',
  WORKBOOK_CLICK_INTO_ANOTHER_WORKBOOK: 'WORKBOOK_CLICK_INTO_ANOTHER_WORKBOOK',
  WORKBOOK_EDIT_WORKSHEET: 'WORKBOOK_EDIT_WORKSHEET',
  WORKBOOK_EDIT_WORKSHEET_CONNECTOR: 'WORKBOOK_EDIT_WORKSHEET_CONNECTOR',
  WORKBOOK_DELETE_WORKSHEET: 'WORKBOOK_DELETE_WORKSHEET',
  WORKBOOK_CLICK_TO_GO_TO_NEXT_WORKSHEET_TAB: 'WORKBOOK_CLICK_TO_GO_TO_NEXT_WORKSHEET_TAB',
};
export type WorkbookActions = keyof typeof WORKBOOK_ACTIONS;

export const WORKSHEET_ACTIONS = {
  WORKSHEET_CHANGE_ROW_COUNT: 'WORKSHEET_CHANGE_ROW_COUNT',
  WORKSHEET_SORT_BY_COLUMN: 'WORKSHEET_SORT_BY_COLUMN',
  WORKSHEET_SEARCH_IN_RECORDS: 'WORKSHEET_SEARCH_IN_RECORDS',
  WORKSHEET_IMPORT_CSV: 'WORKSHEET_IMPORT_CSV',
  WORKSHEET_EXPORT_CSV: 'WORKSHEET_EXPORT_CSV',
  WORKSHEET_EXPORT_AS_TEST_DATA: 'WORKSHEET_EXPORT_AS_TEST_DATA',
  WORKSHEET_CHANGE_COLUMN_VISIBILITY: 'WORKSHEET_CHANGE_COLUMN_VISIBILITY',
  WORKSHEET_DATA_DISPLAY_TOGGLE: 'WORKSHEET_DATA_DISPLAY_TOGGLE',
  WORKSHEET_VIEW_ALL_DATA: 'WORKSHEET_VIEW_ALL_DATA',
  WORKSHEET_ADD_ROW: 'WORKSHEET_ADD_ROW',
  WORKSHEET_DELETE_ROW: 'WORKSHEET_DELETE_ROW',
  WORKSHEET_CHANGE_COLUMN_WIDTH: 'WORKSHEET_CHANGE_COLUMN_WIDTH',
  WORKSHEET_FREEZE_COLUMN_TO_LEFT: 'WORKSHEET_FREEZE_COLUMN_TO_LEFT',
  WORKSHEET_MOVE_COLUMNS: 'WORKSHEET_MOVE_COLUMNS',
  WORKSHEET_CHANGE_DATA: 'WORKSHEET_CHANGE_DATA',
  WORKBOOK_WORKSHEETS_READ: 'WORKBOOK_WORKSHEETS_READ',
  WORKBOOK_WORKSHEETS_BATCH_ADD_COLUMNS: 'WORKBOOK_WORKSHEETS_BATCH_ADD_COLUMNS',
  WORKBOOK_WORKSHEETS_ADD_NEW_COLUMN: 'WORKBOOK_WORKSHEETS_ADD_NEW_COLUMN',
  WORKBOOK_WORKSHEETS_ADD_COLUMN_TO_RIGHT: 'WORKBOOK_WORKSHEETS_ADD_COLUMN_TO_RIGHT',
  WORKBOOK_WORKSHEETS_ADD_COLUMN_TO_LEFT: 'WORKBOOK_WORKSHEETS_ADD_COLUMN_TO_LEFT',
  WORKBOOK_WORKSHEETS_EDIT_COLUMNS: 'WORKBOOK_WORKSHEETS_EDIT_COLUMNS',
  WORKBOOK_WORKSHEETS_DELETE_COLUMN: 'WORKBOOK_WORKSHEETS_DELETE_COLUMN',
};
export type WorksheetActions = keyof typeof WORKSHEET_ACTIONS;

export const WORKSHEETS_ACTIONS = {
  WORKSHEETS_CREATE: 'worksheets:create',
  WORKSHEETS_DELETE: 'worksheets:delete',
  WORKSHEETS_READ: 'worksheets:read',
  WORKSHEETS_UPDATE: 'worksheets:update',
} as const;
export type WorksheetsActions = ValueOf<typeof WORKSHEETS_ACTIONS>;

export const COMPONENT_ACTIONS = {
  COMPONENTS_CREATE: 'components:create',
  COMPONENTS_DELETE: 'components:delete',
  COMPONENTS_READ: 'components:read',
  COMPONENTS_UPDATE: 'components:update',
} as const;
export type ComponentActions = ValueOf<typeof COMPONENT_ACTIONS>;

export const COMPONENT_TEMPLATE_INTERNAL_ACTIONS = {
  COMPONENT_TEMPLATES_INTERNAL_CREATE: 'component_templates:internal:create',
  COMPONENT_TEMPLATES_INTERNAL_DELETE: 'component_templates:internal:delete',
  COMPONENT_TEMPLATES_INTERNAL_READ: 'component_templates:internal:read',
  COMPONENT_TEMPLATES_INTERNAL_UPDATE: 'component_templates:internal:update',
} as const;

export const COMPONENT_TEMPLATE_ACTIONS = {
  COMPONENT_TEMPLATES_READ: 'component_templates:read',
} as const;

export type ComponentTemplateActions = ValueOf<typeof COMPONENT_TEMPLATE_ACTIONS>;

export const COMMISSION_PLAN_ACTIONS = {
  COMMISSION_PLAN_READ: 'COMMISSION_PLAN_READ',
  COMMISSION_PLAN_DELETE: 'COMMISSION_PLAN_DELETE',
  COMMISSION_PLAN_EDIT: 'COMMISSION_PLAN_EDIT',
  COMMISSION_PLAN_CLONE: 'COMMISSION_PLAN_CLONE',
  COMMISSION_PLAN_CHANGE_ACTIVE: 'COMMISSION_PLAN_CHANGE_ACTIVE',
  COMMISSION_PLAN_CREATE_PERIOD_GROUP: 'COMMISSION_PLAN_CREATE_PERIOD_GROUP',
  COMMISSION_PLAN_CLONE_PERIOD_GROUP: 'COMMISSION_PLAN_CLONE_PERIOD_GROUP',
  COMMISSION_PLAN_READ_STATEMENT_TEMPLATE: 'COMMISSION_PLAN_READ_STATEMENT_TEMPLATE',
  COMMISSION_PLAN_DELETE_STATEMENT_TEMPLATE: 'COMMISSION_PLAN_DELETE_STATEMENT_TEMPLATE',
  COMMISSION_PLAN_EDIT_STATEMENT_TEMPLATE: 'COMMISSION_PLAN_EDIT_STATEMENT_TEMPLATE',
};
export type CommissionPlanActions = keyof typeof COMMISSION_PLAN_ACTIONS;

export const PERIOD_GROUP_ACTIONS = {
  PERIOD_GROUP_READ: 'PERIOD_GROUP_READ',
  PERIOD_GROUP_EDIT: 'PERIOD_GROUP_EDIT',
  PERIOD_GROUP_DELETE: 'PERIOD_GROUP_DELETE',
  PERIOD_GROUP_CHANGE_ACTIVE: 'PERIOD_GROUP_CHANGE_ACTIVE',
  PERIOD_GROUP_CREATE_PAYOUT_DATE: 'PERIOD_GROUP_CREATE_PAYOUT_DATE',
  PERIOD_GROUP_DELETE_PAYOUT_DATE: 'PERIOD_GROUP_DELETE_PAYOUT_DATE',
  PERIOD_GROUP_CHANGE_VISIBILITY_OF_WORKSHEETS: 'PERIOD_GROUP_CHANGE_VISIBILITY_OF_WORKSHEETS',
  PERIOD_GROUP_ADD_WORKSHEET: 'PERIOD_GROUP_ADD_WORKSHEET',
  PERIOD_GROUP_READ_HISTORICAL_WORKSHEET: 'PERIOD_GROUP_READ_HISTORICAL_WORKSHEET',
  PERIOD_GROUP_ADD_HISTORICAL_WORKSHEET: 'PERIOD_GROUP_ADD_HISTORICAL_WORKSHEET',
  PERIOD_GROUP_SORT_WORKSHEET_TABS: 'PERIOD_GROUP_SORT_WORKSHEET_TABS',
  PERIOD_GROUP_EDIT_HISTORICAL_WORKSHEET: 'PERIOD_GROUP_EDIT_HISTORICAL_WORKSHEET',
  PERIOD_GROUP_EDIT_HIERARCHY_WORKSHEET: 'PERIOD_GROUP_EDIT_HIERARCHY_WORKSHEET',
  PERIOD_GROUP_EDIT_DERIVED_WORKSHEET: 'PERIOD_GROUP_EDIT_DERIVED_WORKSHEET',
  PERIOD_GROUP_EDIT_FUNNEL_WORKSHEET: 'PERIOD_GROUP_EDIT_FUNNEL_WORKSHEET',
  PERIOD_GROUP_CLONE_DERIVED_WORKSHEET: 'PERIOD_GROUP_CLONE_DERIVED_WORKSHEET',
  PERIOD_GROUP_CLONE_HISTORICAL_WORKSHEET: 'PERIOD_GROUP_CLONE_HISTORICAL_WORKSHEET',
  PERIOD_GROUP_DELETE_WORKSHEET: 'PERIOD_GROUP_DELETE_WORKSHEET',
  PERIOD_GROUP_DELETE_HISTORICAL_WORKSHEET: 'PERIOD_GROUP_DELETE_HISTORICAL_WORKSHEET',
  PERIOD_GROUP_STATEMENT_DESIGNER_ACTIONS: 'PERIOD_GROUP_STATEMENT_DESIGNER_ACTIONS',
  PERIOD_GROUP_READ_STATEMENT_TEMPLATE: 'PERIOD_GROUP_READ_STATEMENT_TEMPLATE',
  PERIOD_GROUP_DELETE_STATEMENT_TEMPLATE: 'PERIOD_GROUP_DELETE_STATEMENT_TEMPLATE',
  PERIOD_GROUP_EDIT_STATEMENT_TEMPLATE: 'PERIOD_GROUP_EDIT_STATEMENT_TEMPLATE',
  PERIOD_GROUP_WORKSHEETS_CHANGE_ROW_COUNT: 'PERIOD_GROUP_WORKSHEETS_CHANGE_ROW_COUNT',
  PERIOD_GROUP_WORKSHEETS_SORT_BY_COLUMN: 'PERIOD_GROUP_WORKSHEETS_SORT_BY_COLUMN',
  PERIOD_GROUP_WORKSHEETS_SEARCH_IN_RECORDS: 'PERIOD_GROUP_WORKSHEETS_SEARCH_IN_RECORDS',
  PERIOD_GROUP_WORKSHEETS_IMPORT_CSV: 'PERIOD_GROUP_WORKSHEETS_IMPORT_CSV',
  PERIOD_GROUP_WORKSHEETS_EXPORT_CSV: 'PERIOD_GROUP_WORKSHEETS_EXPORT_CSV',
  PERIOD_GROUP_WORKSHEETS_CHANGE_COLUMN_VISIBILITY: 'PERIOD_GROUP_WORKSHEETS_CHANGE_COLUMN_VISIBILITY',
  PERIOD_GROUP_WORKSHEETS_DATA_DISPLAY_TOGGLE: 'PERIOD_GROUP_WORKSHEETS_DATA_DISPLAY_TOGGLE',
  PERIOD_GROUP_WORKSHEETS_BATCH_ADD_COLUMNS: 'PERIOD_GROUP_WORKSHEETS_BATCH_ADD_COLUMNS',
  PERIOD_GROUP_WORKSHEETS_ADD_NEW_COLUMN: 'PERIOD_GROUP_WORKSHEETS_ADD_NEW_COLUMN',
  PERIOD_GROUP_WORKSHEETS_ADD_COLUMN_TO_RIGHT: 'PERIOD_GROUP_WORKSHEETS_ADD_COLUMN_TO_RIGHT',
  PERIOD_GROUP_WORKSHEETS_ADD_COLUMN_TO_LEFT: 'PERIOD_GROUP_WORKSHEETS_ADD_COLUMN_TO_LEFT',
  PERIOD_GROUP_WORKSHEETS_EDIT_COLUMNS: 'PERIOD_GROUP_WORKSHEETS_EDIT_COLUMNS',
  PERIOD_GROUP_WORKSHEETS_DELETE_COLUMN: 'PERIOD_GROUP_WORKSHEETS_DELETE_COLUMN',
  PERIOD_GROUP_WORKSHEETS_FREEZE_COLUMN_TO_LEFT: 'PERIOD_GROUP_WORKSHEETS_FREEZE_COLUMN_TO_LEFT',
  PERIOD_GROUP_WORKSHEETS_MOVE_COLUMNS: 'PERIOD_GROUP_WORKSHEETS_MOVE_COLUMNS',
  PERIOD_GROUP_WORKSHEETS_CHANGE_DATA: 'PERIOD_GROUP_WORKSHEETS_CHANGE_DATA',
  PERIOD_GROUP_WORKSHEETS_ADD_ROW: 'PERIOD_GROUP_WORKSHEETS_ADD_ROW',
  PERIOD_GROUP_WORKSHEETS_DELETE_ROW: 'PERIOD_GROUP_WORKSHEETS_DELETE_ROW',
};
export type PeriodGroupActions = keyof typeof PERIOD_GROUP_ACTIONS;

export type Actions = WorkbookActions | WorksheetActions | CommissionPlanActions | PeriodGroupActions;

export const CONNECTORS_ACTIONS = {
  CONNECTORS_CREATE: 'connectors:create',
  CONNECTORS_DELETE: 'connectors:delete',
  CONNECTORS_READ: 'connectors:read',
  CONNECTORS_UPDATE: 'connectors:update',
} as const;
export type ConnectorsActions = ValueOf<typeof CONNECTORS_ACTIONS>;

export const COMMISSIONS_ACTIONS = {
  COMMISSIONS_CREATE: 'commissions:create',
  COMMISSIONS_DELETE: 'commissions:delete',
  COMMISSIONS_EXPORT: 'commissions:export',
  COMMISSIONS_READ: 'commissions:read',
  COMMISSIONS_SHARE: 'commissions:share',
  COMMISSIONS_UPDATE: 'commissions:update',
} as const;
export type CommissionsActions = ValueOf<typeof COMMISSIONS_ACTIONS>;

export const PAYOUTS_ACTIONS = {
  PAYOUTS_READ: 'payouts:read',
  PAYOUTS_CREATE: 'payouts:create',
  PAYOUTS_DELETE: 'payouts:delete',
  PAYOUTS_EXPORT: 'payouts:export',
  PAYOUTS_UPDATE: 'payouts:update',
  PAYOUTS_PROCESS_SHARED: 'payouts:process_via_shared_plans',
  PAYOUTS_DELETE_SHARED: 'payouts:delete_via_shared_plans',
  PAYOUTS_READ_SHARED: 'payouts:read_via_shared_plans',
  PAYOUTS_UPDATE_SHARED: 'payouts:update_via_shared_plans',
  PAYOUTS_CREATE_SHARED: 'payouts:create_via_shared_plans',
} as const;
export type PayoutsActions = ValueOf<typeof PAYOUTS_ACTIONS>;

export const EMPLOYEES_ACTIONS = {
  EMPLOYEES_CREATE: 'employees:create',
  EMPLOYEES_READ: 'employees:read',
  EMPLOYEES_UPDATE: 'employees:update',
  EMPLOYEES_DELETE: 'employees:delete',
} as const;
export type EmployeesActions = ValueOf<typeof EMPLOYEES_ACTIONS>;

export const HIERARCHIES_ACTIONS = {
  HIERARCHIES_CREATE: 'hierarchies:create',
  HIERARCHIES_READ: 'hierarchies:read',
  HIERARCHIES_UPDATE: 'hierarchies:update',
  HIERARCHIES_DELETE: 'hierarchies:delete',
  HIERARCHIES_EXPORT: 'hierarchies:export',
} as const;
export type HierarchiesActions = ValueOf<typeof HIERARCHIES_ACTIONS>;

export const INQUIRIES_ACTIONS = {
  INQUIRIES_LIMIT: 'inquiries:limit',
  INQUIRIES_EXPORT: 'inquiries:export',
  INQUIRIES_READ: 'inquiries:read',
  INQUIRIES_UPDATE: 'inquiries:update',
} as const;
export type InquiriesActions = ValueOf<typeof INQUIRIES_ACTIONS>;

export const APPROVALS_ACTIONS = {
  APPROVALS_READ: 'approvals:read',
} as const;
export type ApprovalsActions = ValueOf<typeof APPROVALS_ACTIONS>;

export const INQUIRY_CATEGORY_ACTIONS = {
  INQUIRY_CATEGORIES_READ: 'inquiry_categories:read',
  INQUIRY_CATEGORIES_CREATE: 'inquiry_categories:create',
  INQUIRY_CATEGORIES_UPDATE: 'inquiry_categories:update',
  INQUIRY_CATEGORIES_DELETE: 'inquiry_categories:delete',
} as const;
export type InquiryCategoryActions = ValueOf<typeof INQUIRY_CATEGORY_ACTIONS>;

export const KNOWLEDGE_CENTER_ACTIONS = {
  KNOWLEDGE_CENTER: 'knowledge_center:read',
} as const;
export type KnowledgeCenterActions = ValueOf<typeof KNOWLEDGE_CENTER_ACTIONS>;

export const PERIOD_GROUPS_ACTIONS = {
  PERIOD_GROUPS_CREATE: 'period_groups:create',
  PERIOD_GROUPS_DELETE: 'period_groups:delete',
  PERIOD_GROUPS_READ: 'period_groups:read',
  PERIOD_GROUPS_UPDATE: 'period_groups:update',
} as const;
export type PeriodGroupsActions = ValueOf<typeof PERIOD_GROUPS_ACTIONS>;

export const ORGANIZATION_ACTIONS = {
  ORGANIZATION_READ: 'organization:read',
  ORGANIZATION_UPDATE: 'organization:update',
  ORGANIZATION_SELECT: 'organization:select',
} as const;
export type OrganizationActions = ValueOf<typeof ORGANIZATION_ACTIONS>;

export const ORGANIZATION_SANDBOX_ACTIONS = {
  ORGANIZATION_SANDBOX_UPDATE: 'organization_sandbox:update',
  ORGANIZATION_SANDBOX_CREATE: 'organization_sandbox:create',
  ORGANIZATION_SANDBOX_SYNC: 'organization_sandbox:sync',
  ORGANIZATION_SANDBOX_SYNC_BACK: 'organization_sandbox:sync_back',
} as const;
export type OrganizationSandboxActions = ValueOf<typeof ORGANIZATION_SANDBOX_ACTIONS>;

export const ORGANIZATION_SETTINGS_ACTIONS = {
  ORGANIZATION_SETTINGS_READ: 'organization_settings:read',
  ORGANIZATION_SETTINGS_UPDATE: 'organization_settings:update',
} as const;
export type OrganizationSettingsActions = ValueOf<typeof ORGANIZATION_SETTINGS_ACTIONS>;

export const WORKBOOKS_ACTIONS = {
  WORKBOOKS_CREATE: 'workbooks:create',
  WORKBOOKS_READ: 'workbooks:read',
  WORKBOOKS_UPDATE: 'workbooks:update',
  WORKBOOKS_DELETE: 'workbooks:delete',
  WORKBOOKS_EXPORT: 'workbooks:export',
  WORKBOOKS_SHARE: 'workbooks:share',
} as const;
export type WorkbooksActions = ValueOf<typeof WORKBOOKS_ACTIONS>;

export const USERS_ACTIONS = {
  USERS_CREATE: 'users:create',
  USERS_READ: 'users:read',
  USERS_UPDATE: 'users:update',
  USERS_DELETE: 'users:delete',
  USERS_IMPERSONATE: 'users:impersonate',
} as const;
export type UsersActions = ValueOf<typeof USERS_ACTIONS>;

export const JOBS_ACTIONS = {
  JOBS_LIMIT: 'jobs:limit',
  JOBS_READ: 'jobs:read',
  JOBS_DELETE: 'jobs:delete',
} as const;
export type JobsActions = ValueOf<typeof JOBS_ACTIONS>;

export const AUDIT_LOGS_ACTIONS = {
  AUDIT_LOGS_READ: 'audit_logs:read',
  AUDIT_LOGS_UPDATE: 'audit_logs:update',
  AUDIT_LOGS_EXPORT: 'audit_logs:export',
} as const;
export type AuditLogsActions = ValueOf<typeof AUDIT_LOGS_ACTIONS>;

export const REPORT_MODELS_ACTIONS = {
  REPORT_MODELS_CREATE: 'report_models:create',
  REPORT_MODELS_DELETE: 'report_models:delete',
  REPORT_MODELS_READ: 'report_models:read',
  REPORT_MODELS_UPDATE: 'report_models:update',
} as const;
export type ReportModelsActions = ValueOf<typeof REPORT_MODELS_ACTIONS>;

export const MASTER_WORKSHEETS_ACTIONS = {
  MASTER_WORKSHEETS_CREATE: 'master_worksheets:create',
  MASTER_WORKSHEETS_UPDATE: 'master_worksheets:update',
  MASTER_WORKSHEETS_EXPORT: 'master_worksheets:export',
  MASTER_WORKSHEETS_DELETE: 'master_worksheets:delete',
  MASTER_WORKSHEETS_READ: 'master_worksheets:read',
} as const;
export type MasterWorksheetActions = ValueOf<typeof MASTER_WORKSHEETS_ACTIONS>;

export const DATA_VARIABLES_ACTIONS = {
  DATA_VARIABLES_CREATE: 'data_variables:create',
  DATA_VARIABLES_DELETE: 'data_variables:delete',
  DATA_VARIABLES_READ: 'data_variables:read',
  DATA_VARIABLES_UPDATE: 'data_variables:update',
} as const;
export type DataVariablesActions = ValueOf<typeof DATA_VARIABLES_ACTIONS>;

export const GLOBAL_ATTRIBUTES_ACTIONS = {
  GLOBAL_ATTRIBUTES_READ: 'global_attributes:read',
  GLOBAL_ATTRIBUTES_CREATE: 'global_attributes:create',
  GLOBAL_ATTRIBUTES_EXPORT: 'global_attributes:export',
  GLOBAL_ATTRIBUTES_UPDATE: 'global_attributes:update',
  GLOBAL_ATTRIBUTES_DELETE: 'global_attributes:delete',
} as const;
export type GlobalAttributesActions = ValueOf<typeof GLOBAL_ATTRIBUTES_ACTIONS>;

export const UNIFIED_EMPLOYEE_ASSUMPTIONS_ACTIONS = {
  UNIFIED_EMPLOYEE_ASSUMPTIONS_READ: 'unified_employee_assumptions:read',
  UNIFIED_EMPLOYEE_ASSUMPTIONS_UPDATE: 'unified_employee_assumptions:update',
  UNIFIED_EMPLOYEE_ASSUMPTIONS_EXPORT: 'unified_employee_assumptions:export',
} as const;
export type UnifiedEmployeeAssumptionActions = ValueOf<typeof UNIFIED_EMPLOYEE_ASSUMPTIONS_ACTIONS>;

export const PAYOUTS_PROCESSING_ACTIONS = {
  PAYOUTS_PROCESSING_CREATE: 'payouts_processing:create',
} as const;
export type PayoutsProcessingActions = ValueOf<typeof PAYOUTS_PROCESSING_ACTIONS>;

export const PAYOUTS_REVIEWS_ACTIONS = {
  PAYOUTS_REVIEW_DELETE: 'payouts_review:delete',
} as const;
export type PayoutsReviewsActions = ValueOf<typeof PAYOUTS_REVIEWS_ACTIONS>;

export const DASHBOARDS_ACTIONS = {
  DASHBOARDS_CREATE: 'dashboards:create',
  DASHBOARDS_DELETE: 'dashboards:delete',
  DASHBOARDS_READ: 'dashboards:read',
  DASHBOARDS_UPDATE: 'dashboards:update',
  DASHBOARDS_PROVISION: 'dashboards:provision',
} as const;
export type DashboardsActions = ValueOf<typeof DASHBOARDS_ACTIONS>;

export const REPORT_OUTPUTS_ACTIONS = {
  REPORT_OUTPUTS_READ: 'report_outputs:read',
  REPORT_OUTPUTS_UPDATE: 'report_outputs:update',
  REPORT_OUTPUTS_CREATE: 'report_outputs:create',
  REPORT_OUTPUTS_DELETE: 'report_outputs:delete',
} as const;
export type ReportOutputsActions = ValueOf<typeof REPORT_OUTPUTS_ACTIONS>;

export const PEOPLE_VISIBILITY_ACTIONS = {
  PEOPLE_VISIBILITY_READ: 'people_visibility:read',
  PEOPLE_VISIBILITY_UPDATE: 'people_visibility:update',
  PEOPLE_VISIBILITY_CREATE: 'people_visibility:create',
  PEOPLE_VISIBILITY_DELETE: 'people_visibility:delete',
} as const;
export type PeopleVisibilityActions = ValueOf<typeof PEOPLE_VISIBILITY_ACTIONS>;

export const PLAN_DOCUMENT_ACTIONS = {
  PLAN_DOCUMENT_READ: 'plan_document:read',
  PLAN_DOCUMENT_CREATE: 'plan_document:create',
  PLAN_DOCUMENT_DELETE: 'plan_document:delete',
} as const;
export type PlanDocumentActions = ValueOf<typeof PLAN_DOCUMENT_ACTIONS>;

export const PLAN_EXECUTIONS_ACTIONS = {
  PLAN_EXECUTIONS_READ: 'plan_executions:read',
  PLAN_EXECUTIONS_UPDATE: 'plan_executions:update',
  PLAN_EXECUTIONS_CREATE: 'plan_executions:create',
  PLAN_EXECUTIONS_DELETE: 'plan_executions:delete',
} as const;
export type PlanExecutionsActions = ValueOf<typeof PLAN_EXECUTIONS_ACTIONS>;

export const QUICK_REPORTING_DASHBOARDS_ACTIONS = {
  QUICK_REPORTING_DASHBOARDS_READ: 'quick_reporting_dashboards:read',
  QUICK_REPORTING_DASHBOARDS_UPDATE: 'quick_reporting_dashboards:update',
  QUICK_REPORTING_DASHBOARDS_CREATE: 'quick_reporting_dashboards:create',
  QUICK_REPORTING_DASHBOARDS_DELETE: 'quick_reporting_dashboards:delete',
  QUICK_REPORTING_DASHBOARDS_SHARE: 'quick_reporting_dashboards:share',
} as const;
export type QuickReportingDashboardsActions = ValueOf<typeof QUICK_REPORTING_DASHBOARDS_ACTIONS>;

export const QUOTA_WORKBOOKS_ACTIONS = {
  QUOTA_WORKBOOKS_READ: 'quota_workbooks:read',
  QUOTA_WORKBOOKS_UPDATE: 'quota_workbooks:update',
  QUOTA_WORKBOOKS_CREATE: 'quota_workbooks:create',
  QUOTA_WORKBOOKS_DELETE: 'quota_workbooks:delete',
} as const;
export type QuotaWorkbooksActions = ValueOf<typeof QUOTA_WORKBOOKS_ACTIONS>;

export const ADVANCED_REPORTING_DASHBOARDS_ACTIONS = {
  ADVANCED_REPORTING_DASHBOARDS_READ: 'advanced_reporting_dashboards:read',
  ADVANCED_REPORTING_DASHBOARDS_UPDATE: 'advanced_reporting_dashboards:update',
  ADVANCED_REPORTING_DASHBOARDS_CREATE: 'advanced_reporting_dashboards:create',
  ADVANCED_REPORTING_DASHBOARDS_DELETE: 'advanced_reporting_dashboards:delete',
  ADVANCED_REPORTING_DASHBOARDS_SHARE: 'advanced_reporting_dashboards:share',
} as const;
export type AdvancedReportingDashboardsActions = ValueOf<typeof ADVANCED_REPORTING_DASHBOARDS_ACTIONS>;

export const ADVANCED_REPORTING_EXPORTS_ACTIONS = {
  ADVANCED_REPORTING_EXPORTS_READ: 'advanced_reporting_exports:read',
  ADVANCED_REPORTING_EXPORTS_UPDATE: 'advanced_reporting_exports:update',
  ADVANCED_REPORTING_EXPORTS_EXPORT: 'advanced_reporting_exports:export',
  ADVANCED_REPORTING_EXPORTS_DELETE: 'advanced_reporting_exports:delete',
} as const;
export type AdvancedReportingExportsActions = ValueOf<typeof ADVANCED_REPORTING_EXPORTS_ACTIONS>;

export const ORGANIZATION_SETTINGS_GENERAL_ACTIONS = {
  ORGANIZATION_SETTINGS_GENERAL_READ: 'organization_settings_general:read',
  ORGANIZATION_SETTINGS_GENERAL_UPDATE: 'organization_settings_general:update',
} as const;
export type OrganizationSettingsGeneralActions = ValueOf<typeof ORGANIZATION_SETTINGS_GENERAL_ACTIONS>;

export const ORGANIZATION_SETTINGS_SSO_ACTIONS = {
  ORGANIZATION_SETTINGS_SSO_READ: 'organization_settings_sso:read',
  ORGANIZATION_SETTINGS_SSO_UPDATE: 'organization_settings_sso:update',
  ORGANIZATION_SETTINGS_SSO_CREATE: 'organization_settings_sso:create',
  ORGANIZATION_SETTINGS_SSO_DELETE: 'organization_settings_sso:delete',
} as const;
export type OrganizationSettingsSSOActions = ValueOf<typeof ORGANIZATION_SETTINGS_SSO_ACTIONS>;

export const ORGANIZATION_SETTINGS_PROVISIONING_ACTIONS = {
  ORGANIZATION_SETTINGS_PROVISIONING_READ: 'organization_settings_provisioning:read',
  ORGANIZATION_SETTINGS_PROVISIONING_UPDATE: 'organization_settings_provisioning:update',
} as const;
export type OrganizationSettingsProvisioningActions = ValueOf<typeof ORGANIZATION_SETTINGS_PROVISIONING_ACTIONS>;

export const ORGANIZATION_SETTINGS_AUTOMATIONS_ACTIONS = {
  ORGANIZATION_SETTINGS_AUTOMATIONS_READ: 'organization_settings_automations:read',
  ORGANIZATION_SETTINGS_AUTOMATIONS_UPDATE: 'organization_settings_automations:update',
} as const;
export type OrganizationSettingsAutomationsActions = ValueOf<typeof ORGANIZATION_SETTINGS_AUTOMATIONS_ACTIONS>;

export const ORGANIZATION_SETTINGS_USER_ROLES_ACTIONS = {
  ORGANIZATION_SETTINGS_USER_ROLES_READ: 'organization_settings_user_roles:read',
  ORGANIZATION_SETTINGS_USER_ROLES_UPDATE: 'organization_settings_user_roles:update',
  ORGANIZATION_SETTINGS_USER_ROLES_CREATE: 'organization_settings_user_roles:create',
  ORGANIZATION_SETTINGS_USER_ROLES_DELETE: 'organization_settings_user_roles:delete',
} as const;
export type OrganizationSettingsUserRolesActions = ValueOf<typeof ORGANIZATION_SETTINGS_USER_ROLES_ACTIONS>;

export const CIQ_INTERNAL_ONLY_ACTIONS = {
  CONNECTORS_INTERNAL_READ: 'connectors:internal:read',
  DASHBOARDS_INTERNAL_DEPROVISION: 'dashboards:internal:deprovision',
  DATA_VARIABLES_INTERNAL_UPDATE: 'data_variables:internal:update',
  COMPONENT_TEMPLATES_INTERNAL_CREATE: 'component_templates:internal:create',
  COMPONENT_TEMPLATES_INTERNAL_UPDATE: 'component_templates:internal:update',
  COMPONENT_TEMPLATES_INTERNAL_DELETE: 'component_templates:internal:delete',
  COMPONENT_TEMPLATES_INTERNAL_READ: 'component_templates:internal:read',
  ORGANIZATION_INTERNAL_CREATE: 'organization:internal:create',
  ORGANIZATION_INTERNAL_READ: 'organization:internal:read',
  ORGANIZATION_INTERNAL_UPDATE: 'organization:internal:update',
  ORGANIZATION_INTERNAL_SELECT: 'organization:internal:select',
  ORGANIZATION_SANDBOX_INTERNAL_CREATE: 'organization_sandbox:internal:create',
  ORGANIZATION_SANDBOX_INTERNAL_READ: 'organization_sandbox:internal:read',
  ORGANIZATION_SANDBOX_INTERNAL_SYNC: 'organization_sandbox:internal:sync',
  WORKATO_ACCOUNT_INTERNAL_CREATE: 'workato_account:internal:create',
  WORKATO_ACCOUNT_INTERNAL_DELETE: 'workato_account:internal:delete',
  WORKATO_ACCOUNT_INTERNAL_READ: 'workato_account:internal:read',
  WORKATO_ACCOUNT_INTERNAL_UPDATE: 'workato_account:internal:update',
  WORKSHEETS_INTERNAL_READ: 'worksheets:internal:read',
} as const;
export type CIQInternalOnlyActions = ValueOf<typeof CIQ_INTERNAL_ONLY_ACTIONS>;

export const TEST_DATA_MODE_ACTIONS = {
  TEST_DATA_MODE_CREATE: 'test_data_mode:create',
  TEST_DATA_MODE_DELETE: 'test_data_mode:delete',
  TEST_DATA_MODE_READ: 'test_data_mode:read',
  TEST_DATA_MODE_UPDATE: 'test_data_mode:update',
};

export type TestDataModeActions = ValueOf<typeof TEST_DATA_MODE_ACTIONS>;

export const TEST_DATA_MODE_CREATE_UPDATE_DELETE_ACTIONS = [
  TEST_DATA_MODE_ACTIONS.TEST_DATA_MODE_CREATE,
  TEST_DATA_MODE_ACTIONS.TEST_DATA_MODE_UPDATE,
  TEST_DATA_MODE_ACTIONS.TEST_DATA_MODE_DELETE,
];

export const ASSISTANT_ACTIONS = {
  ASSISTANT_READ: 'assistant:read',
  ASSISTANT_COPILOT_CREATE: 'assistant:copilot_create',
  ASSISTANT_KNOWLEDGE_BASE_CREATE: 'assistant:knowledge_base_create',
  ASSISTANT_PAYEE_CREATE: 'assistant:payee_create',
} as const;
export type AssistantActions = ValueOf<typeof ASSISTANT_ACTIONS>;

export const TQ_ACTIONS = {
  TQ_READ: 'tq:read',
} as const;
export type TQActions = ValueOf<typeof TQ_ACTIONS>;

export type PermissionStatementAction =
  | ConnectorsActions
  | CommissionsActions
  | ComponentActions
  | ComponentTemplateActions
  | DashboardsActions
  | PlanDocumentActions
  | EmployeesActions
  | GlobalAttributesActions
  | HierarchiesActions
  | InquiriesActions
  | KnowledgeCenterActions
  | PayoutsActions
  | PayoutsReviewsActions
  | QuotaWorkbooksActions
  | PeriodGroupsActions
  | PayoutsProcessingActions
  | TestDataModeActions
  | PlanExecutionsActions
  | UnifiedEmployeeAssumptionActions
  | PeopleVisibilityActions
  | OrganizationActions
  | OrganizationSandboxActions
  | OrganizationSettingsActions
  | WorkbooksActions
  | WorksheetsActions
  | UsersActions
  | JobsActions
  | AuditLogsActions
  | ReportModelsActions
  | ReportOutputsActions
  | MasterWorksheetActions
  | DataVariablesActions
  | CIQInternalOnlyActions
  | QuickReportingDashboardsActions
  | AdvancedReportingDashboardsActions
  | AdvancedReportingExportsActions
  | OrganizationSettingsGeneralActions
  | OrganizationSettingsSSOActions
  | OrganizationSettingsProvisioningActions
  | OrganizationSettingsAutomationsActions
  | OrganizationSettingsUserRolesActions
  | AssistantActions
  | TQActions;

// Virtual roles, cause they are not real roles assignable to user however we'd like to unify how current state
// of organization admin / payee is represented.
export const VIRTUAL_ROLES = {
  EMPLOYEE: 'employee',
  ORGANIZATION_ADMIN: 'organization_admin',
};

export const CIQ_CUSTOM_ROLES = {
  CIQ_INTERNAL_ADMIN: 'CIQ Internal Admin',
  CIQ_READONLY_INTERNAL_ADMIN: 'CIQ Readonly Internal Admin',
  CIQ_ENGINEER: 'CIQ Engineer',
};

export const CIQ_MANAGED_ROLES = {
  GROUP_ADMIN: 'Group Admin',
  IT_ADMIN: 'IT Admin',
};

export const AUTHORIZATION_TYPES = {
  IS_SUPERUSER: 'Is superuser',
  IS_ORGANIZATION_ADMIN: 'Is organization admin',
} as const;
