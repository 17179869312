import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { APIError } from 'utils/errors';

import { getRequest } from './apiRequests';

const apiURL = '/api/csrf';

type CSRFTokenResponse = {
  csrf: string;
};

const getCSRFToken = () => getRequest<null, CSRFTokenResponse>(apiURL);

const useGetCSRFToken = (options: UseQueryOptions<CSRFTokenResponse, APIError> = {}) => {
  return {
    query: useQuery<CSRFTokenResponse, APIError>('csrf', getCSRFToken, {
      ...options,
    }),
  };
};

export const CSRFService = {
  useGetCSRFToken,
};
