import type { ComponentMultiStyleConfig } from 'quotient';

export const Menu: ComponentMultiStyleConfig = {
  parts: ['list', 'item', 'divider', 'groupTitle'],
  baseStyle: {
    list: {
      padding: 0,
      background: 'white',
    },
    item: {
      bg: 'transparent',
      px: 3,
      py: 2,
      _active: {
        bg: 'secondaryNeutral.200',
        color: 'primaryNeutral.900',
      },
      _focus: {
        bg: 'secondaryNeutral.200',
        color: 'primaryNeutral.900',
      },
    },
    divider: {
      m: 0,
    },
    groupTitle: {
      mx: 3,
      my: 2,
    },
  },
};
