import { useModalContext } from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

import { QuotientCloseButton } from './CloseButton';

type QuotientModalCloseButtonProps = {
  _className?: string;
  onClose?: () => void;
  isDisabled?: boolean;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientModalCloseButton = ({
  _className,
  onClose,
  isDisabled,
  ...props
}: QuotientModalCloseButtonProps) => {
  const { onClose: chakraOnClose } = useModalContext();
  return (
    <QuotientCloseButton
      _className={_className}
      insetEnd="3"
      isDisabled={isDisabled}
      position="absolute"
      top="2"
      onClose={callAllHandlers(onClose, (event: React.MouseEvent) => {
        event.stopPropagation();
        chakraOnClose();
      })}
      {...props}
    />
  );
};
