import { getRequest } from 'services/apiRequests';
import { StringifiedUUID } from 'types';

const apiUrl = '/api/v1/job-aggregations/';

type JobsAggregationQueryJobDataDetailsType = {
  download_id: string;
  download_url: string;
};

export enum JobsAggregationStatus {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
}

export type JobsAggregationQueryJobDataType = {
  status: JobsAggregationStatus;
  details: JobsAggregationQueryJobDataDetailsType;
  url: string;
};

export type JobsAggregationQueryType = {
  status: JobsAggregationStatus;
  data: Array<JobsAggregationQueryJobDataType>;
};

const getJobsAggregationQuery = (jobId: StringifiedUUID) => {
  return getRequest<null, JobsAggregationQueryType>(`${apiUrl}${jobId}?include_tasks=true`);
};

export const JobsService = {
  getJobsAggregationQuery,
};
