import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faXmark } from '@fortawesome/pro-regular-svg-icons/faXmark';
import debounce from 'lodash/debounce';
import { useRef, useState } from 'react';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

import { QuotientInput } from '../Input/Input';

export type QuotientSearchProps = {
  isDisabled?: boolean;
  isInvalid?: boolean;
  placeholder?: string;
  onSearch: (value: string) => void;
} & SelectedStyledSystemProps &
  CommonCIQProps;

const search = (onSearch: (value: string) => void, value: string) => onSearch(value);

const debouncedSearch = debounce(search, 125);

export const QuotientSearch = ({ isDisabled, isInvalid, placeholder, onSearch, ...rest }: QuotientSearchProps) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const [searchValue, setSearchValue] = useState('');

  return (
    <QuotientInput
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      leftIcon={faMagnifyingGlass}
      placeholder={placeholder}
      ref={searchRef}
      rightIcon={searchValue.length > 0 && !isDisabled ? faXmark : undefined}
      type="search"
      onChange={(e) => {
        const value = e.target.value;
        setSearchValue(value);
        debouncedSearch(onSearch, value);
      }}
      onRightIconClick={() => {
        setSearchValue('');
        if (searchRef.current) {
          searchRef.current.value = '';
        }
        onSearch('');
      }}
      {...rest}
    />
  );
};
