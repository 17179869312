import type { QueryClientConfig } from 'react-query';

export const QUERY_STALE_TIME_IN_SECONDS = 30 * 60 * 60;

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: QUERY_STALE_TIME_IN_SECONDS,
      retry: false,
    },
  },
};
