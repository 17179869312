import { faXmarkLarge } from '@fortawesome/pro-regular-svg-icons';
import { MouseEventHandler } from 'react';

import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

import { QuotientIconButton } from '../IconButton/IconButton';

type QuotientCloseButtonProps = {
  _className?: string;
  onClose: MouseEventHandler;
  isDisabled?: boolean;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientCloseButton = ({ _className, onClose, isDisabled, ...props }: QuotientCloseButtonProps) => {
  return (
    <QuotientIconButton
      _className={_className}
      colorScheme="brand"
      icon={faXmarkLarge}
      isDisabled={isDisabled}
      unmask
      onClick={onClose}
      {...props}
    />
  );
};
