import { Field, FieldProps } from '../Field';
import { QuotientSelect, QuotientSelectProps } from '../Select/Select';

type SelectGroupFieldProps = FieldProps & QuotientSelectProps;

export const QuotientSelectGroupField = ({
  id,
  label,
  description,
  isRequired,
  showOptional,
  isInvalid,
  errorMessage,
  value,
  placeholder,
  isDisabled,
  leftIcon,
  children,
  onChange,
  onBlur,
  'data-testid': dataTestId,
  ...rest
}: SelectGroupFieldProps) => {
  return (
    <Field
      description={description}
      errorMessage={errorMessage}
      id={id}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      showOptional={showOptional}
      {...rest}
    >
      <QuotientSelect
        data-testid={dataTestId}
        isDisabled={isDisabled}
        leftIcon={leftIcon}
        placeholder={placeholder}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      >
        {children}
      </QuotientSelect>
    </Field>
  );
};
