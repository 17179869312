// copypasta'd from ICM and cut down
import { createContext, useContext } from 'react';
import * as React from 'react';

import { PERMISSION_HIERARCHY } from 'constants/permissions';
import { UsersService } from 'services';
import { AuthorizationType } from 'types/permissions';

import { AUTHORIZATION_TYPES, PermissionStatementAction, PermissionTypes } from './constants';
import { SectionPermissionCheckPayload, PermissionCheckPayloadSimplified, PermissioningKeysTypes } from './types';

export type PermissionsContextType = {
  hasSectionPermission: (
    permissionCheckPayload?: SectionPermissionCheckPayload,
    authType?: AuthorizationType,
  ) => boolean;
  hasAccess: (permissionCheckPayload: PermissionCheckPayloadSimplified) => boolean;
  arePermissionsReady: boolean;
};

export const createDefaultContextValues = (): PermissionsContextType => ({
  hasSectionPermission: () => true,
  hasAccess: () => true,
  arePermissionsReady: true,
});

export const PermissionsContext = createContext<PermissionsContextType>(createDefaultContextValues());
PermissionsContext.displayName = 'PermissionsContext';

// copypasta'd from ICM
export const PermissionsProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { query: currentUserQuery } = UsersService.useGetCurrentUserQuery(); // TODO: shift this to a context
  const isCurrentUserLoaded = !currentUserQuery.isLoading && currentUserQuery.isSuccess;
  const currentUser = isCurrentUserLoaded ? currentUserQuery.data : null;
  const {
    query: { data: userPermissions, isLoading },
  } = UsersService.useGetCurrentUserPermissions({
    enabled: isCurrentUserLoaded && currentUser?.is_organization_admin === false,
  });
  const sharedResources = userPermissions?.shared_resources || [];
  const rolePermissionStatements = userPermissions?.role?.permission_statements || [];
  const userAllowedActions = rolePermissionStatements.map((statement) => statement.action);

  const permissionsCache = sharedResources.reduce(
    (cache, permission) => ({
      ...cache,
      [permission.resource_id]: permission.permission_type,
    }),
    {} as Record<string, PermissionTypes>,
  );

  const hasSectionPermission: PermissionsContextType['hasSectionPermission'] = (
    permissionCheckPayload = {},
    authType: AuthorizationType = AUTHORIZATION_TYPES.IS_ORGANIZATION_ADMIN,
  ) => {
    if (
      currentUser?.is_superuser ||
      (authType === AUTHORIZATION_TYPES.IS_ORGANIZATION_ADMIN && currentUser?.is_organization_admin)
    ) {
      return true;
    }

    if (userPermissions?.can_user_be_assigned_role === false) {
      return false;
    }

    const requiredSectionAction = permissionCheckPayload?.action;

    if (!requiredSectionAction) {
      return false;
    }
    return !!userAllowedActions.includes(requiredSectionAction);
  };

  const getSharedAccess = (permissioningKeys: PermissioningKeysTypes): string[] => {
    let allPermissions: string[] = [];
    Object.values(permissioningKeys).forEach((resourceId) => {
      const permissionType = permissionsCache[resourceId];
      if (permissionType) {
        allPermissions = allPermissions.concat(PERMISSION_HIERARCHY[permissionType]);
      }
    });

    return Array.from(new Set(allPermissions));
  };

  const hasAccess = ({
    actions,
    permissioningKeys,
    requiredShareAccess,
    beforeCheck,
  }: PermissionCheckPayloadSimplified): boolean => {
    if (actions) {
      const actionList: PermissionStatementAction[] = Array.isArray(actions) ? actions : [actions];
      const isSectionPermissionGranted = actionList.some((action) => hasSectionPermission({ action }));
      if (isSectionPermissionGranted) {
        return true;
      }
    }

    if (permissioningKeys && requiredShareAccess) {
      if (beforeCheck && !beforeCheck()) {
        return false;
      }

      return getSharedAccess(permissioningKeys).includes(requiredShareAccess);
    }

    return false;
  };

  return (
    <PermissionsContext.Provider
      value={{
        hasSectionPermission,
        hasAccess,
        arePermissionsReady: !isLoading,
      }}
    >
      {children}
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => useContext(PermissionsContext);
