import { ComponentStyleConfig, FormControl, FormLabel, Switch as ChakraSwitch } from '@chakra-ui/react';
import { ChangeEvent } from 'react';

import { textStyles } from 'quotient/theme/foundations/typography/typography';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const SwitchStyle: ComponentStyleConfig = {
  baseStyle: {
    track: {
      bg: 'surfaceBackgroundMediumContrastReverse',
      _checked: {
        bg: 'surfaceBrandDefault',
      },
      _focus: {
        boxShadow: 'none',
        outlineColor: 'primary.300',
        outlineWidth: '2px',
        outlineStyle: 'solid',
      },
      _focusVisible: {
        boxShadow: 'none',
        outlineColor: 'primary.300',
        outlineWidth: '2px',
        outlineStyle: 'solid',
      },
      _disabled: {
        bg: 'borderNeutralPrimaryMediumContrast',
      },
    },
  },
};

export type QuotientSwitchProps = {
  isChecked: boolean;
  isDisabled?: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  children?: string | null;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientSwitch: React.FC<QuotientSwitchProps> = ({
  isChecked,
  isDisabled,
  onChange,
  children,
  ...props
}) => {
  return (
    <FormControl alignItems="center" display="flex" gap={2}>
      <ChakraSwitch isChecked={isChecked} isDisabled={isDisabled} mb={0} onChange={onChange} {...props} />
      <FormLabel color={isDisabled ? 'textLowContrast' : 'textHighContrast'} m={0} {...textStyles.bodyRegular}>
        {children}
      </FormLabel>
    </FormControl>
  );
};
