export const space = {
  0: '0px',
  px: '1px',
  /** Special cases */
  0.5: '2px',
  1: '4px',
  /** Special cases */
  1.5: '6px',
  /** Padding between supporting icons & text */
  2: '8px',
  /** Do not use. Used internally by Chakra. */
  2.5: '10px',
  /** Horizontal padding for input fields & tables */
  3: '12px',
  /** Do not use. Used internally by Chakra. */
  3.5: '14px',
  /** Base spacing size */
  4: '16px',
  /** Do not use. Used internally by Chakra. */
  5: '20px',
  /** Default container padding */
  6: '24px',
  /** Do not use. Used internally by Chakra. */
  7: '28px',
  /** Default page margins between elements */
  8: '32px',
  /** Do not use. Used internally by Chakra. */
  9: '36px',
  /** Do not use. Used internally by Chakra. */
  10: '40px',
  /** Default horizontal & vertical page margins */
  12: '48px',
  /** Do not use. Used internally by Chakra. */
  14: '56px',
  16: '64px',
  /** Do not use. Used internally by Chakra. */
  20: '80px',
  24: '96px',

  /** Do not use. Used internally by Chakra. */
  max: 'max-content',
  /** Do not use. Used internally by Chakra. */
  min: 'min-content',
  /** Do not use. Used internally by Chakra. */
  full: '100%',
  /** Do not use. Used internally by Chakra. */
  '3xs': '14rem',
  /** Do not use. Used internally by Chakra. */
  '2xs': '16rem',
  /** Do not use. Used internally by Chakra. */
  xs: '20rem',
  /** Do not use. Used internally by Chakra. */
  sm: '24rem',
  /** Do not use. Used internally by Chakra. */
  md: '28rem',
  /** Do not use. Used internally by Chakra. */
  lg: '32rem',
  /** Do not use. Used internally by Chakra. */
  xl: '36rem',
  /** Do not use. Used internally by Chakra. */
  '2xl': '42rem',
  /** Do not use. Used internally by Chakra. */
  '3xl': '48rem',
  /** Do not use. Used internally by Chakra. */
  '4xl': '56rem',
  /** Do not use. Used internally by Chakra. */
  '5xl': '64rem',
  /** Do not use. Used internally by Chakra. */
  '6xl': '72rem',
  /** Do not use. Used internally by Chakra. */
  '7xl': '80rem',
  /** Do not use. Used internally by Chakra. */
  '8xl': '90rem',
  /** Do not use. Used internally by Chakra. */
  container: {
    /** Do not use. Used internally by Chakra. */
    sm: '640px',
    /** Do not use. Used internally by Chakra. */
    md: '768px',
    /** Do not use. Used internally by Chakra. */
    lg: '1024px',
    /** Do not use. Used internally by Chakra. */
    xl: '1280px',
  },
} as const;
