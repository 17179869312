import { useQuery, useQueryClient } from 'react-query';

import { APIList, StringifiedUUID } from 'types';
import { Workbook, GetWorksheetResponse, GetSnapshotResponse } from 'types/worksheets';
import { APIError } from 'utils/errors';

import { getRequest } from './apiRequests';

const workbooksUrl = '/api/v1/data-workbooks/';
const worksheetUrl = '/api/v1/data-worksheets/';

const getSnapshotByWorksheetUrl = (worksheetId: StringifiedUUID) =>
  `/api/v1/workbooks/worksheets/${worksheetId}/snapshots`;

const listDataWorksheets = () => getRequest<null, APIList<Workbook>>(workbooksUrl);

const getDataWorksheet = (worksheetId: StringifiedUUID) =>
  getRequest<null, GetWorksheetResponse>(`${worksheetUrl}${worksheetId}/`);

const getSnapshotByWorksheetId = (worksheetId: StringifiedUUID) => {
  const url = getSnapshotByWorksheetUrl(worksheetId);
  return getRequest<null, GetSnapshotResponse>(url);
};

const useGetSnapshotByWorksheet = (worksheetId: StringifiedUUID, enabled: boolean) => {
  const QUERY_KEY = ['snapshot-by-worksheet', worksheetId];
  const queryClient = useQueryClient();

  const query = useQuery<GetSnapshotResponse, APIError>({
    queryKey: QUERY_KEY,
    queryFn: () => getSnapshotByWorksheetId(worksheetId),
    enabled,
  });
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const useGetDataWorksheets = () => {
  const QUERY_KEY = ['data-worksheets'];
  const queryClient = useQueryClient();

  const query = useQuery<APIList<Workbook>, APIError>(QUERY_KEY, () => listDataWorksheets());
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const useGetDataWorksheet = (worksheetId: StringifiedUUID, enabled: boolean) => {
  const QUERY_KEY = ['data-worksheet', worksheetId];
  const queryClient = useQueryClient();

  const query = useQuery<GetWorksheetResponse, APIError>({
    queryKey: QUERY_KEY,
    queryFn: () => getDataWorksheet(worksheetId),
    enabled,
  });
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

export const DataWorksheetService = {
  apiUrl: workbooksUrl,
  dataWorksheetApiUrl: worksheetUrl,
  getSnapshotByWorksheetUrl,
  getDataWorksheet,
  useGetDataWorksheets,
  useGetDataWorksheet,
  useGetSnapshotByWorksheet,
};
