import moment from 'moment';

import { Box, Flex, Text, Avatar } from 'quotient';

import { Author } from './ThreadContainer';

export type Comment = {
  id?: string;
  author: Author;
  content: string;
  timestamp: string;
};

export type CommentHeaderProps = {
  name: string;
  avatarURL?: string;
  timestamp?: string;
};

export const CommentHeader: React.VFC<CommentHeaderProps> = ({ name, avatarURL, timestamp }) => {
  const sanitizedTimestamp = timestamp ? new Date(timestamp) : new Date();

  return (
    <Flex alignItems="center" direction="row">
      <Avatar name={name} size="sm" src={avatarURL || ''} />
      <Text marginLeft="0.75em" marginY={0} textStyle="bodySemiBold">
        {name}
      </Text>
      <Text color="primaryNeutral.400" fontSize="12px" marginLeft="1em" marginY={0}>
        {moment(sanitizedTimestamp).format('D MMM YYYY, h:mm a')}
      </Text>
    </Flex>
  );
};

export type ThreadCommentsProps = {
  comments: Comment[];
};

export const ThreadComments: React.VFC<ThreadCommentsProps> = ({ comments }) => {
  return (
    <Box marginLeft="2em">
      {comments.map((comment) => (
        <Box marginTop="1em">
          <CommentHeader
            avatarURL={comment.author.avatarURL}
            name={comment.author.name}
            timestamp={comment.timestamp}
          />
          <Flex direction="row" marginLeft="calc(24px + 0.75em)" whiteSpace="pre-wrap">
            {comment.content}
          </Flex>
        </Box>
      ))}
    </Box>
  );
};
