// copypasta'd from ICM
export const DATA_AND_CONNECTIONS = 'Data & connections';
export const COMMISSION_PLANS = 'Commission plans';
export const PAYOUTS = 'Payouts';
export const INQUIRIES = 'Inquiries';
export const QUICK_REPORTING = 'Quick reporting';
export const ADVANCED_REPORTING = 'Advanced reporting';
export const PLAN_DOCUMENTS = 'Plan documents';
export const PEOPLE = 'People';
export const ACTIVITY = 'Activity';
// In order to differentiate between the two activity sections, see space at the end of
export const ORGANIZATIONAL_SETTINGS = 'Organizational settings';
export const SANDBOX = 'Sandbox';
export const OTHER = 'Other';
export const KNOWLEDGE_CENTER = 'Knowledge Center';
export const ASSISTANT = 'Assistant';
export const TQ = 'Territories & Quota';

export const LIMIT = 'limit';
export const READ = 'read';
export const UPDATE = 'update';
export const CREATE = 'create';
export const DELETE = 'delete';

// This is the order the permissions will be displayed. Ensure the order gets updated as sections are finalised.
export const PERMISSION_ORDER: string[] = [
  DATA_AND_CONNECTIONS,
  COMMISSION_PLANS,
  PAYOUTS,
  INQUIRIES,
  QUICK_REPORTING,
  ADVANCED_REPORTING,
  PLAN_DOCUMENTS,
  PEOPLE,
  ORGANIZATIONAL_SETTINGS,
  ACTIVITY,
  KNOWLEDGE_CENTER,
  SANDBOX,
  ASSISTANT,
  TQ,
];

export const UNDER_CONSTRUCTION_PERMISSION_ORDER: string[] = [];

export const STANDARD_ACCESS: string[] = [LIMIT, READ, UPDATE, CREATE, DELETE];
export const PERMISSION_HIERARCHY: { [key: string]: string[] } = {
  limit: ['limit'],
  read: ['limit', 'read'],
  update: ['limit', 'read', 'update'],
  create: ['limit', 'read', 'update', 'create'],
  delete: ['limit', 'read', 'update', 'create', 'delete'],
};

export type GROUPED_PERMISSIONS_TYPE = {
  standardAccess: string[];
  additionalAccess: string[];
  humanReadable: string;
  description: string;
  id: string;
};

export type ACCESS_TYPE = {
  standard_access: string;
  additional_access: string[];
};

export type ACTION_TYPE = {
  [key: string]: ACCESS_TYPE;
};

export type ROLE_FORM_VALUES_TYPES = {
  name: string;
  description: string;
};

type permissionMetaDataType = {
  header: string;
  humanReadable: string;
  description: string;
  checkboxes: { [key: string]: CHECKBOX_METADATA_TYPE[] };
};

export type CHECKBOX_METADATA_TYPE = {
  id: string;
  humanReadable: string;
  editable: boolean;
  description: string;
};

// Order needs to be maintained in this map. Please do not make changes unless intending to change order on UI.
export const PERMISSION_META_DATA: { [key: string]: permissionMetaDataType } = {
  // DATA_AND_CONNECTIONS grouping
  workbooks: {
    header: DATA_AND_CONNECTIONS,
    humanReadable: 'Workbooks',
    description: 'Workbooks & worksheets store the source data used for commission calculations.',
    checkboxes: {
      read: [{ id: 'export', humanReadable: 'Export', editable: false, description: 'Export workbook data.' }],
      update: [
        {
          id: 'share',
          humanReadable: 'Share',
          editable: true,
          description: 'Share workbooks & worksheets with other users.',
        },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Add/delete columns and values in worksheets within the workbooks.',
        },
        {
          id: 'state',
          humanReadable: 'Set active/inactive',
          editable: false,
          description: 'Show and hide workbooks for easier organization.',
        },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create new workbooks.' },
        {
          id: 'clone',
          humanReadable: 'Clone',
          editable: false,
          description: 'Clone existing workbooks into new ones.',
        },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete workbooks.' }],
    },
  },
  global_attributes: {
    header: DATA_AND_CONNECTIONS,
    humanReadable: 'Global Attributes',
    description: 'Global Attributes store essential information that can be used across commission plans.',
    checkboxes: {
      read: [
        {
          id: 'add_filters',
          humanReadable: 'Add filters',
          editable: false,
          description: 'Apply filters to a worksheet.',
        },
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search for records within a worksheet with keywords.',
        },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export worksheets into a CSV file.' },
      ],
      update: [
        {
          id: 'import',
          humanReadable: 'Import',
          editable: false,
          description: 'Import CSV files into global attribute worksheets.',
        },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Add/delete columns and edit values in a worksheet.',
        },
      ],
      create: [
        {
          id: 'create',
          humanReadable: 'Create',
          editable: false,
          description: 'Create new global attribute worksheets.',
        },
      ],
      delete: [
        {
          id: 'delete',
          humanReadable: 'Delete',
          editable: false,
          description: 'Delete columns and values in a worksheet, as well as entire worksheets.',
        },
      ],
    },
  },
  components: {
    header: DATA_AND_CONNECTIONS,
    humanReadable: 'Components',
    description: 'This section is used to house reusable calculations for your commission plans.',
    checkboxes: {
      read: [
        {
          id: 'view',
          humanReadable: 'View component',
          editable: false,
          description: 'View component overview page with all of its worksheets.',
        },
        {
          id: 'list',
          humanReadable: 'List components',
          editable: false,
          description: 'View list of components in your organization.',
        },
        {
          id: 'view_worksheets',
          humanReadable: 'View worksheets',
          editable: false,
          description: 'View transformations of a worksheet.',
        },
        {
          id: 'view_instance',
          humanReadable: 'View instance',
          editable: false,
          description: 'View the component instance overview page.',
        },
        {
          id: 'export',
          humanReadable: 'Export records',
          editable: false,
          description: 'Export worksheet records into a CSV file.',
        },
      ],
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit component name/description.',
        },
        {
          id: 'edit_instances',
          humanReadable: 'Edit instances',
          editable: false,
          description: 'Sync updates from a component to all of its instances.',
        },
        {
          id: 'edit_worksheets',
          humanReadable: 'Edit worksheets',
          editable: false,
          description: 'Edit existing worksheets in a component page.',
        },
        {
          id: 'delete_cols_and_worksheets',
          humanReadable: 'Delete columns and worksheets',
          editable: false,
          description: 'Delete columns and values in a worksheet, as well as entire worksheets.',
        },
        {
          id: 'create_worksheets',
          humanReadable: 'Create worksheets',
          editable: false,
          description: 'Create new worksheets in a component.',
        },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create new component.' },
        {
          id: 'import',
          humanReadable: 'Import records',
          editable: false,
          description: 'Import worksheet records from a CSV file.',
        },
      ],
      delete: [
        { id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete components.' },
        {
          id: 'detach_instance',
          humanReadable: 'Detach component instance',
          editable: false,
          description: 'Detach a component instance from a commission plan.',
        },
      ],
    },
  },
  connectors: {
    header: DATA_AND_CONNECTIONS,
    humanReadable: 'Data Connectors',
    description: 'Data Connectors and Integrations used to import data into CaptivateIQ.',
    checkboxes: {
      read: [
        {
          id: 'view',
          humanReadable: 'View',
          editable: false,
          description: 'View existing data connectors and their configuration details.',
        },
      ],
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit data connector details and manage their sync schedules.',
        },
      ],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Add new data connectors.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete data connectors.' }],
    },
  },

  // COMMISSION_PLANS grouping
  commissions: {
    header: COMMISSION_PLANS,
    humanReadable: 'Commissions Plans & Period Groups',
    description: 'Commission plans and period groups used to calculate incentive compensation.',
    checkboxes: {
      read: [
        { id: 'Search', humanReadable: 'Search', editable: false, description: 'Search for plans.' },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export payout summaries.' },
      ],
      update: [
        {
          id: 'share',
          humanReadable: 'Share',
          editable: true,
          description: 'Share commission plans and period groups with other users.',
        },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit plans, plan settings, add period groups and worksheets.',
        },
        {
          id: 'state',
          humanReadable: 'Set active/inactive',
          editable: false,
          description: 'Show and hide workbooks for easier organization.',
        },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create new commission plans.' },
        {
          id: 'clone',
          humanReadable: 'Clone',
          editable: false,
          description: 'Clone existing commission plans into new ones.',
        },
      ],
      delete: [
        {
          id: 'delete',
          humanReadable: 'Delete',
          editable: false,
          description: 'Delete commission plans.',
        },
      ],
    },
  },
  unified_employee_assumptions: {
    header: COMMISSION_PLANS,
    humanReadable: 'Unified employee assumptions',
    description:
      'Employee assumptions worksheets store employee related information used across multiple ' +
      'period groups and commissions plans.',
    checkboxes: {
      read: [
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter for records by plan and period groups.',
        },
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search for employee assumptions.',
        },
        {
          id: 'export',
          humanReadable: 'Export',
          editable: true,
          description: 'Export employee assumptions.',
        },
        {
          id: 'history',
          humanReadable: 'Download History',
          editable: false,
          description: 'View historical records of exports.',
        },
      ],
      update: [
        { id: 'import', humanReadable: 'Import', editable: false, description: 'Import CSV files into worksheet.' },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Modify existing columns and values in the worksheet.',
        },
        {
          id: 'state',
          humanReadable: 'Set active/inactive',
          editable: false,
          description: 'Show and hide workbooks for easier organization.',
        },
      ],
    },
  },

  // PAYOUTS grouping
  payouts_processing: {
    header: PAYOUTS,
    humanReadable: 'Processing',
    description: 'This section contains controls associated with managing and processing payouts.',
    checkboxes: {
      create: [
        {
          id: 'process_payouts',
          humanReadable: 'Process payouts',
          editable: false,
          description: 'Schedule processes to run calculations.',
        },
      ],
    },
  },
  payouts: {
    header: PAYOUTS,
    humanReadable: 'Payouts',
    description: 'This section contains Payouts and workflows for reviews and approvals.',
    checkboxes: {
      read: [
        {
          id: 'Filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter for records by plan, date, employee and/or attribute.',
        },
        { id: 'preview', humanReadable: 'Preview', editable: false, description: 'View employee statements.' },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export payout data.' },
      ],
      update: [
        {
          id: 'publish',
          humanReadable: 'Publish workflows',
          editable: false,
          description: 'Submit payouts and publish statements for review.',
        },
        { id: 'download', humanReadable: 'Download', editable: false, description: 'Download payout statements.' },
        {
          id: 'email',
          humanReadable: 'Email',
          editable: false,
          description: 'Send email notifications for payout reviews.',
        },
        { id: 'edit', humanReadable: 'Edit', editable: false, description: 'Modify status of a payout.' },
      ],
      create: [
        {
          id: 'send',
          humanReadable: 'Send',
          editable: false,
          description: 'Send email notifications when payouts are ready.',
        },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete payouts.' }],
    },
  },
  payouts_review: {
    header: PAYOUTS,
    humanReadable: 'Payout reviews',
    description: 'This section contains reviews associated with Payouts.',
    checkboxes: {
      delete: [
        {
          id: 'review',
          humanReadable: 'Review',
          editable: false,
          description: 'Review payouts.',
        },
      ],
    },
  },

  // INQUIRIES grouping
  inquiries: {
    header: INQUIRIES,
    humanReadable: 'Inquiries',
    description: 'Inquiries to manage and resolve commission questions.',
    checkboxes: {
      limit: [
        {
          id: 'limit_view',
          humanReadable: 'Limited view',
          editable: false,
          description: 'User can view, edit and approve any Inquiry assigned to them',
        },
      ],
      read: [
        {
          id: 'view_all',
          humanReadable: 'View All',
          editable: false,
          description: 'User can view all Inquiries',
        },
        { id: 'export', humanReadable: 'Export', editable: true, description: 'User can export inquiries' },
      ],
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'User can edit & approve all inquiries.',
        },
      ],
    },
  },
  approvals: {
    header: INQUIRIES,
    humanReadable: 'Approvals',
    description: 'Managing approvals for inquiries.',
    checkboxes: {
      read: [
        {
          id: 'view',
          humanReadable: 'View',
          editable: false,
          description: 'View approvals.',
        },
      ],
    },
  },

  // QUICK_REPORTING grouping
  quick_reporting_dashboards: {
    header: QUICK_REPORTING,
    humanReadable: 'Dashboards',
    description: 'Reports on attainment and aggregated metrics.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search dashboards and visualizations by keywords.',
        },
      ],
      update: [
        {
          id: 'share',
          humanReadable: 'Share',
          editable: true,
          description: 'Share dashboards internally and externally.',
        },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit existing dashboards and visualizations.',
        },
      ],
      create: [
        {
          id: 'create',
          humanReadable: 'Create',
          editable: false,
          description: 'Create new dashboards and visualizations.',
        },
      ],
      delete: [
        {
          id: 'delete',
          humanReadable: 'Delete',
          editable: false,
          description: 'Delete dashboards and visualizations.',
        },
      ],
    },
  },
  data_variables: {
    header: QUICK_REPORTING,
    humanReadable: 'Data variables',
    description: 'Key data metrics to be used in reports.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search data variable records by keywords.',
        },
      ],
      update: [{ id: 'edit', humanReadable: 'Edit', editable: false, description: 'Edit data variables.' }],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Create data variables.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete data variables.' }],
    },
  },

  // ADVANCED_REPORTING grouping
  advanced_reporting_dashboards: {
    header: ADVANCED_REPORTING,
    humanReadable: 'Dashboards',
    description: 'Reports on combined & aggregated data of multiple processed calculations.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search dashboards, visualizations, and advanced reports by keywords.',
        },
      ],
      update: [
        {
          id: 'share',
          humanReadable: 'Share',
          editable: true,
          description: 'Share dashboards internally and externally.',
        },
        {
          id: 'sync',
          humanReadable: 'Sync',
          editable: false,
          description: 'Sync dashboards.',
        },
      ],
      create: [
        {
          id: 'create',
          humanReadable: 'Create',
          editable: false,
          description: 'Create dashboards and visualizations.',
        },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete dashboards.' }],
    },
  },
  report_outputs: {
    header: ADVANCED_REPORTING,
    humanReadable: 'Exports',
    description: 'Reports to export.',
    checkboxes: {
      read: [
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter exports by report model, date, and locked status.',
        },
        {
          id: 'export',
          humanReadable: 'Export',
          editable: false,
          description: 'Export worksheet.',
        },
      ],
      update: [{ id: 'lock', humanReadable: 'Lock', editable: false, description: 'Lock status.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete report.' }],
    },
  },
  report_models: {
    header: ADVANCED_REPORTING,
    humanReadable: 'Report models',
    description: 'Calculations performed from Unified worksheet data sets.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search report models by keywords and type.',
        },
      ],
      update: [
        {
          id: 'sync',
          humanReadable: 'Sync',
          editable: false,
          description: 'Sync marked worksheets in visualization report models for reporting use.',
        },
      ],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Create report models.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete report models.' }],
    },
  },
  master_worksheets: {
    header: ADVANCED_REPORTING,
    humanReadable: 'Unified worksheets',
    description: 'Combined plan-level calculation data.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search unified worksheets by keywords.',
        },
        { id: 'export', humanReadable: 'Export', editable: true, description: 'Export worksheet data.' },
      ],
      update: [{ id: 'import', humanReadable: 'Import', editable: false, description: 'Import worksheet data.' }],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Create unified worksheets.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete unified worksheets.' }],
    },
  },

  // PLAN_DOCUMENTS grouping
  plan_executions: {
    header: PLAN_DOCUMENTS,
    humanReadable: 'Envelopes',
    description: 'Plan documents to share with payees in your organization.',
    checkboxes: {
      read: [
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter document records by email, start date, end date, and status.',
        },
      ],
      update: [
        {
          id: 'update',
          humanReadable: 'Update',
          editable: false,
          description: 'Update docusign credentials.',
        },
      ],
      create: [
        { id: 'send', humanReadable: 'Send Envelopes', editable: false, description: 'Send Envelopes to employees.' },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete a plan document.' }],
    },
  },
  plan_document: {
    header: PLAN_DOCUMENTS,
    humanReadable: 'Other documents',
    description:
      'This section stores other uploaded documents that are shared with users & payees across your organization. ',
    checkboxes: {
      read: [
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter document records by email, start date, end date, and status.',
        },
      ],
      create: [
        { id: 'upload', humanReadable: 'Upload', editable: false, description: 'Upload a plan document.' },
        { id: 'share', humanReadable: 'Share', editable: false, description: 'Share documents.' },
        { id: 'reminders', humanReadable: 'Reminders', editable: false, description: 'Send acceptance reminders.' },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete a plan document.' }],
    },
  },
  quota_workbooks: {
    header: PLAN_DOCUMENTS,
    humanReadable: 'Document data',
    description: 'Manage the data that can be sourced for populating plan envelopes.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search document data by keywords.',
        },
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter document records by email, start date, end date, and status.',
        },
      ],
      update: [
        {
          id: 'import',
          humanReadable: 'Import',
          editable: false,
          description: 'Import CSV file into document data worksheet.',
        },
        {
          id: 'export',
          humanReadable: 'Export',
          editable: false,
          description: 'Export document data into a CSV file.',
        },
        { id: 'edit', humanReadable: 'Edit', editable: false, description: 'Edit columns and values.' },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create document data.' },
        { id: 'clone', humanReadable: 'Clone', editable: false, description: 'Clone document data.' },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete a plan document.' }],
    },
  },

  // PEOPLE grouping
  employees: {
    header: PEOPLE,
    humanReadable: 'Employees',
    description: 'Employees who receive payouts via commission plans.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search employees by name, email or ID.',
        },
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter employees by license status, commission plan assignment, and commission plans.',
        },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export employees into a CSV file.' },
      ],
      update: [
        { id: 'link', humanReadable: 'Link', editable: false, description: 'Link an employee to a user profile.' },
        { id: 'edit', humanReadable: 'Edit', editable: false, description: 'Edit employee records.' },
        {
          id: 'deactivate',
          humanReadable: 'Deactivate',
          editable: false,
          description: 'Deactivate license for employee.',
        },
      ],
      create: [
        {
          id: 'create',
          humanReadable: 'Create',
          editable: false,
          description: 'Create new employees in your organization.',
        },
        {
          id: 'import',
          humanReadable: 'Import',
          editable: false,
          description: 'Import CSV file to generate employees.',
        },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete employees.' }],
    },
  },
  users: {
    header: PEOPLE,
    humanReadable: 'Users',
    description: "Users provide login access to your organization's CIQ admin console.",
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search users by employee name, email or ID.',
        },
        { id: 'impersonate', humanReadable: 'Impersonate', editable: true, description: 'Impersonate users.' },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export users into a CSV file.' },
      ],
      update: [
        { id: 'import', humanReadable: 'Import', editable: false, description: 'Import CSV file to generate users.' },
        { id: 'link', humanReadable: 'Link', editable: false, description: 'Link a user to employee attributes.' },
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit user information and employee visibility.',
        },
        { id: 'activate', humanReadable: 'Activate', editable: false, description: 'Activate users in the system.' },
        { id: 'reset', humanReadable: 'Reset', editable: false, description: 'Send a password reset for user.' },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create new users in the system.' },
      ],
      delete: [{ id: 'deactivate', humanReadable: 'Deactivate', editable: false, description: 'Deactivate users.' }],
    },
  },
  hierarchies: {
    header: PEOPLE,
    humanReadable: 'Hierarchies',
    description: 'Store and manage reporting relationships for your employees.',
    checkboxes: {
      read: [
        { id: 'search', humanReadable: 'Search', editable: false, description: 'Search hierarchy groups by keywords.' },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export hierarchies as a CSV file.' },
      ],
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit hierarchy groups and reporting relationships.',
        },
      ],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Create hierarchy groups.' }],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete hierarchy groups.' }],
    },
  },
  people_visibility: {
    header: PEOPLE,
    humanReadable: 'Visibility',
    description: 'Controls information visible to managers in their Payee console.',
    checkboxes: {
      read: [
        {
          id: 'search',
          humanReadable: 'Search',
          editable: false,
          description: 'Search users by name, ID, start and end date.',
        },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export users as a CSV file.' },
      ],
      update: [
        { id: 'edit', humanReadable: 'Edit', editable: false, description: 'Edit existing employee visibilities.' },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Add new user visibility setting.' },
        {
          id: 'import',
          humanReadable: 'Import',
          editable: false,
          description: 'Import a CSV file to generate user records.',
        },
      ],
      delete: [
        { id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete user visibility settings.' },
      ],
    },
  },

  // ACTIVITY grouping
  jobs: {
    header: ACTIVITY,
    humanReadable: 'Jobs',
    description: 'Historical records of jobs performed in the system.',
    checkboxes: {
      limit: [
        {
          id: 'limit_view',
          humanReadable: 'Limited view',
          editable: false,
          description: 'Full access only to the jobs that users performed themselves.',
        },
        {
          id: 'filter',
          humanReadable: 'Filter',
          editable: false,
          description: 'Filter jobs by email, start date, end date, and job type.',
        },
        {
          id: 'refresh',
          humanReadable: 'Refresh',
          editable: false,
          description: 'Refresh for the most up to date records.',
        },
        { id: 'view', humanReadable: 'View', editable: false, description: 'View details of a jobs group.' },
      ],
      read: [
        { id: 'view_all', humanReadable: 'View all jobs', editable: false, description: 'See all triggered jobs.' },
      ],
      update: [{ id: 'export', humanReadable: 'Export', editable: false, description: 'Export job records.' }],
      delete: [{ id: 'cancel', humanReadable: 'Cancel', editable: false, description: 'Cancel any job.' }],
    },
  },
  audit_logs: {
    header: ACTIVITY,
    humanReadable: 'Audit logs',
    description: 'Historical records of actions performed in the system.',
    checkboxes: {
      read: [
        { id: 'filter', humanReadable: 'Filter', editable: false, description: 'Filter actions by many dimensions.' },
        { id: 'export', humanReadable: 'Export', editable: false, description: 'Export audit logs.' },
      ],
      update: [{ id: 'edit', humanReadable: 'Edit', editable: false, description: 'Update saved filters.' }],
    },
  },

  // ORGANIZATIONAL_SETTINGS grouping
  organization_settings_general: {
    header: ORGANIZATIONAL_SETTINGS,
    humanReadable: 'General',
    description: 'Date format settings, security settings, and reporting currency.',
    checkboxes: {
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Manage date format settings, security settings, and reporting currency.',
        },
        {
          id: 'upload',
          humanReadable: 'Upload',
          editable: false,
          description: 'Upload logos used in rep-facing experiences.',
        },
      ],
    },
  },
  organization_settings_sso: {
    header: ORGANIZATIONAL_SETTINGS,
    humanReadable: 'Single sign-on',
    description: 'Single sign-on settings',
    checkboxes: {
      update: [{ id: 'edit', humanReadable: 'Edit', editable: false, description: 'Manage single sign-on settings.' }],
      create: [
        {
          id: 'create',
          humanReadable: 'Create',
          editable: false,
          description: 'Add a new single sign-on SAML provider.',
        },
      ],
      delete: [
        {
          id: 'delete',
          humanReadable: 'Delete',
          editable: false,
          description: 'Delete single sign-on SAML providers.',
        },
      ],
    },
  },
  organization_settings_provisioning: {
    header: ORGANIZATIONAL_SETTINGS,
    humanReadable: 'Provisioning',
    description: 'User default settings, automated provisioning and provider configurations.',
    checkboxes: {
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Manage user default settings, automated provisioning and provider configurations.',
        },
      ],
    },
  },
  organization_settings_automations: {
    header: ORGANIZATIONAL_SETTINGS,
    humanReadable: 'Automations',
    description: 'Employee off-boarding and employee licenses automation settings',
    checkboxes: {
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Manage employee off-boarding and employee licenses.',
        },
      ],
    },
  },
  organization_settings_user_roles: {
    header: ORGANIZATIONAL_SETTINGS,
    humanReadable: 'User roles',
    description: 'Role-based access controls.',
    checkboxes: {
      update: [
        {
          id: 'edit',
          humanReadable: 'Edit',
          editable: false,
          description: 'Edit access settings on existing user roles.',
        },
      ],
      create: [
        { id: 'create', humanReadable: 'Create', editable: false, description: 'Create a new custom user role.' },
      ],
      delete: [{ id: 'delete', humanReadable: 'Delete', editable: false, description: 'Delete custom user roles.' }],
    },
  },

  // SANDBOX grouping
  organization_sandbox: {
    header: SANDBOX,
    humanReadable: 'Sandbox',
    description: 'Testing environment that mirrors your production account.',
    checkboxes: {
      update: [
        { id: 'sync', humanReadable: 'Sync', editable: true, description: 'Sync from production to sandbox.' },
        {
          id: 'sync_back',
          humanReadable: 'Sync back',
          editable: true,
          description: 'Sync from sandbox back to production.',
        },
      ],
      create: [{ id: 'create', humanReadable: 'Create', editable: false, description: 'Create sandboxes.' }],
    },
  },

  knowledge_center: {
    header: KNOWLEDGE_CENTER,
    humanReadable: 'Knowledge Center',
    description: 'Knowledge Center.',
    checkboxes: {
      read: [{ id: 'read', humanReadable: 'View', editable: false, description: 'Access knowledge center.' }],
    },
  },

  assistant: {
    header: ASSISTANT,
    humanReadable: 'Assistant',
    description: 'CIQ Assist',
    checkboxes: {
      read: [
        { id: 'payee_create', humanReadable: 'Payee', editable: true, description: 'Access CIQ Assist Payee Coach.' },
        {
          id: 'copilot_create',
          humanReadable: 'Copilot',
          editable: true,
          description: 'Access CIQ Assist Copilot.',
        },
        {
          id: 'knowledge_base_create',
          humanReadable: 'Knowledge Base',
          editable: true,
          description: 'Access CIQ Assist Knowledge Base.',
        },
      ],
    },
  },
  tq: {
    header: TQ,
    humanReadable: 'Territories & Quota',
    description: 'Permissions to the Territories and Quota product',
    checkboxes: {
      read: [
        {
          id: 'read',
          humanReadable: 'Admin',
          editable: false,
          description: 'Full permissions to territory & quota data',
        },
      ],
    },
  },

  // Not displayed on UI, they will eventually need to be deprecated with access and controls are stable.
  period_groups: { header: OTHER, humanReadable: 'Period groups', description: '', checkboxes: {} },
  test_data_mode: { header: OTHER, humanReadable: 'Test data mode', description: '', checkboxes: {} },
  workato_account: { header: OTHER, humanReadable: 'Workato account', description: '', checkboxes: {} },
  worksheets: { header: OTHER, humanReadable: 'Worksheets', description: '', checkboxes: {} },
  inquiry_categories: { header: OTHER, humanReadable: 'Inquiry categories', description: '', checkboxes: {} },
  downloads: { header: OTHER, humanReadable: 'Downloads', description: '', checkboxes: {} },
  datasets: { header: OTHER, humanReadable: 'Datasets', description: '', checkboxes: {} },
  organization_settings: { header: OTHER, humanReadable: 'Organization settings', description: '', checkboxes: {} },
  widgets: { header: OTHER, humanReadable: 'Widgets', description: '', checkboxes: {} },
};

export const SLIDER_DISPLAY_NAME: { [key: string]: string } = {
  limit: 'Limited View',
  read: `View`,
  update: 'Edit',
  create: 'Create',
  delete: 'Delete',
};
