import { Badge as ChakraBadge, forwardRef } from '@chakra-ui/react';

import { ComponentStyleConfig } from 'quotient';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const BadgeStyle: ComponentStyleConfig = {
  baseStyle: {
    borderRadius: '4px',
    textTransform: 'none',
    fontWeight: 600,
    width: 'fit-content',
  },
  sizes: {
    xs: {
      textStyle: 'smallSemiBold',
      height: '20px',
      paddingX: '6px',
      paddingY: '0px',
    },
    sm: {
      textStyle: 'smallSemiBold',
      height: '24px',
      paddingX: '8px',
      paddingY: '2px',
    },
    md: {
      textStyle: 'bodySemiBold',
      height: '26px',
      paddingX: '8px',
      paddingY: '2px',
    },
  },
  variants: {
    neutralPrimarySubtle: {
      background: 'primaryNeutral.200',
      color: 'primaryNeutral.900',
    },
    neutralPrimarySolid: {
      background: 'primaryNeutral.600',
      color: 'primaryNeutral.white',
    },
    brandSubtle: {
      background: 'primary.200',
      color: 'primary.900',
    },
    brandSolid: {
      background: 'primary.600',
      color: 'primaryNeutral.white',
    },
    brandSecondarySubtle: {
      background: 'secondary.200',
      color: 'secondary.900',
    },
    brandSecondarySolid: {
      background: 'secondary.600',
      color: 'primaryNeutral.white',
    },
    brandTertiarySubtle: {
      background: 'tertiary.200',
      color: 'tertiary.900',
    },
    brandTertiarySolid: {
      background: 'tertiary.900',
      color: 'primaryNeutral.white',
    },
    brandQuaternarySubtle: {
      background: 'quaternary.200',
      color: 'quaternary.900',
    },
    brandQuaternarySolid: {
      background: 'quaternary.600',
      color: 'primaryNeutral.white',
    },
    positiveSubtle: {
      background: 'positive.50',
      color: 'positive.600',
    },
    positiveSolid: {
      background: 'positive.600',
      color: 'primaryNeutral.white',
    },
    warningSubtle: {
      background: 'warning.50',
      color: 'warning.800',
    },
    warningSolid: {
      background: 'warning.800',
      color: 'primaryNeutral.white',
    },
    dangerSubtle: {
      background: 'danger.50',
      color: 'danger.800',
    },
    dangerSolid: {
      background: 'danger.600',
      color: 'primaryNeutral.white',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'neutralPrimarySubtle',
  },
};

export type QuotientBadgeProps = {
  children: React.ReactNode;
  size?: 'xs' | 'sm' | 'md';
  variant:
    | 'neutralPrimarySubtle'
    | 'neutralPrimarySolid'
    | 'brandSubtle'
    | 'brandSolid'
    | 'brandSecondarySubtle'
    | 'brandSecondarySolid'
    | 'brandTertiarySubtle'
    | 'brandTertiarySolid'
    | 'brandQuaternarySubtle'
    | 'brandQuaternarySolid'
    | 'positiveSubtle'
    | 'positiveSolid'
    | 'warningSubtle'
    | 'warningSolid'
    | 'dangerSubtle'
    | 'dangerSolid';
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientBadge = forwardRef<QuotientBadgeProps, 'span'>(({ children, size, variant, ...props }, ref) => {
  return (
    <ChakraBadge ref={ref} size={size} variant={variant} {...props}>
      {children}
    </ChakraBadge>
  );
});
