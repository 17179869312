export const colors = {
  primaryNeutral: {
    /** Primary copy & system icons */
    900: '#1A202C',
    800: '#202A3C',
    /** Secondary copy */
    700: '#637084',
    /** Placeholder copy; interaction element borders */
    600: '#8795A8',
    /** Disabled interaction elements */
    500: '#A0AEC0',
    /** Borders & dividers */
    400: '#CBD5E0',
    300: '#DDE5EE',
    200: '#E8EEF4',
    /** Hover state backgrounds */
    100: '#EFF3F6',
    /** Disabled field backgrounds; alternating display-only spreadsheet row backgrounds */
    50: '#F6F8FB',
    /** Backgrounds */
    white: '#FFFFFF',
  },
  secondaryNeutral: {
    /** Brand taupe */
    400: '#DAD7D3',
    300: '#E5E4E2',
    /** Table header backgrounds */
    200: '#F4F3F2',
    /** Backgrounds */
    50: '#FBFBFB',
  },
  primary: {
    /** Header background color */
    900: '#052A33',
    /** Header accent color */
    800: '#0A404E',
    700: '#095166',
    /** Button & link hover color */
    600: '#08607A',
    500: '#016C89',
    /** Button & link default color; primary brand shade */
    400: '#007EA1',
    /** Disabled color for CTAs */
    300: '#76C0D9',
    200: '#CEE9F2',
    /** Background color for active states */
    100: '#E9F7FC',
    50: '#F6FDFF',
  },
  secondary: {
    /** Text */
    900: '#1E0253',
    /** Icons & borders */
    600: '#8349F0',
    /** Background */
    200: '#EBE1FF',
    /** Alternating row background */
    50: '#F8F5FE',
  },
  tertiary: {
    /** Text */
    900: '#4E6C10',
    /** Icons & borders */
    600: '#A7D841',
    /** Background */
    200: '#E6FBBA',
    /** Alternating row background */
    50: '#F8FCEF',
  },
  quaternary: {
    /** Text */
    900: '#7C2B01',
    /** Icons & borders */
    600: '#E05E19',
    /** Background */
    200: '#FAE8DE',
    /** Alternating row background */
    50: '#FEF8F4',
  },
  positive: {
    /** Positive pressed/hover */
    800: '#06602A',
    /** Positive primary text and buttons */
    600: '#008937',
    /** Positive disabled */
    200: '#79D79E',
    /** Positive background */
    50: '#D5FCE2',
  },
  warning: {
    /** Warning text */
    800: '#7D5E00',
    /** Warning disabled */
    600: '#E3C053',
    /** Warning primary */
    200: '#FFDD74',
    /** Warning background */
    50: '#FFF2CB',
  },
  danger: {
    900: '#A00000',
    /** Danger pressed/hover */
    800: '#B80000',
    /** Danger primary text and buttons */
    600: '#E32323',
    /** Danger disabled */
    200: '#FF8383',
    /** Danger background */
    50: '#FFE3E3',
  },
  primaryWorkbook: {
    /** Text */
    900: '#163933',
    /** Borders */
    600: '#3C9987',
    /** Background */
    200: '#CEF4EE',
    /** Alternating row background */
    50: '#EAFBF8',
  },
  secondaryWorkbook: {
    /** Text */
    900: '#650451',
    /** Borders */
    600: '#AD188E',
    /** Background */
    200: '#F9D5F1',
    /** Alternating row background */
    50: '#FFF2FC',
  },
  tertiaryWorkbook: {
    /** Text */
    900: '#444C09',
    /** Borders */
    600: '#A5B900',
    /** Background */
    200: '#ECF4A0',
    /** Alternating row background */
    50: '#FBFEDD',
  },
  qualitative: {
    /** Data visualization only */
    1: '#08607A',
    /** Data visualization only */
    2: '#B693FF',
    /** Data visualization only */
    3: '#D9EE22',
    /** Data visualization only */
    4: '#FA8E55',
    /** Data visualization only */
    5: '#35B6C5',
    /** Data visualization only */
    6: '#AD188E',
    /** Data visualization only */
    7: '#94D808',
    /** Data visualization only */
    8: '#238FFA',
    /** Data visualization only */
    9: '#0A404E',
    /** Data visualization only */
    10: '#AEBBCC',
    /** Data visualization only */
    11: '#6930E0',
    /** Data visualization only */
    12: '#EE89D9',
    /** Data visualization only */
    13: '#0DA481',
    /** Data visualization only */
    14: '#002FB9',
    /** Data visualization only */
    15: '#5CC3E5',
    /** Data visualization only */
    16: '#9D3903',
  },
  blackAlpha: {
    /** Do not use. Used internally by Chakra. */
    50: 'rgba(45, 55, 72, 0.04)',
    /** Do not use. Used internally by Chakra. */
    100: 'rgba(45, 55, 72, 0.06)',
    /** Do not use. Used internally by Chakra. */
    200: 'rgba(45, 55, 72, 0.08)',
    /** Do not use. Used internally by Chakra. */
    300: 'rgba(45, 55, 72, 0.16)',
    /** Do not use. Used internally by Chakra. */
    400: 'rgba(45, 55, 72, 0.24)',
    /** Do not use. Used internally by Chakra. */
    500: 'rgba(45, 55, 72, 0.36)',
    /** Do not use. Used internally by Chakra. */
    600: 'rgba(45, 55, 72, 0.48)',
    /** Do not use. Used internally by Chakra. */
    700: 'rgba(45, 55, 72, 0.64)',
    /** Do not use. Used internally by Chakra. */
    800: 'rgba(45, 55, 72, 0.80)',
    /** Do not use. Used internally by Chakra. */
    900: 'rgba(45, 55, 72, 0.92)',
  },
} as const;

export const globalNavOnlySemanticColor= {
  surfaceNavigationDefaultNavOnly: {
    default: 'secondaryNeutral.50',
    _dark: 'primaryNeutral.800',
  },
  borderNeutralPrimaryMediumContrastNavOnly: {
    default: 'primaryNeutral.400',
    _dark: 'primaryNeutral.700',
  },
  borderNeutralSecondaryMediumContrastNavOnly: {
    default: 'secondaryNeutral.400',
    _dark: 'primaryNeutral.700',
  },
  textMediumContrastNavOnly: {
    default: 'primaryNeutral.700',
    _dark: 'primaryNeutral.400',
  },
  surfaceNavigationActiveNavOnly: {
    default: 'primary.100',
    _dark: 'primaryNeutral.600',
  },
  surfaceNavigationHoverNavOnly: {
    default: 'secondaryNeutral.200',
    _dark: 'primaryNeutral.700',
  },
  textHighContrastNavOnly: {
    default: 'primaryNeutral.900',
    _dark: 'primaryNeutral.50',
  },
  textNavigationActiveNavOnly: {
    default: 'primary.600',
    _dark: 'primary.800',
  },
}

export const semanticColors = {
  surfaceBackgroundHighContrast: {
    default: 'primaryNeutral.white',
    // _dark: 'primaryNeutral.900',
  },
  surfaceBackgroundMediumContrast: {
    default: 'primaryNeutral.100',
    // _dark: 'primaryNeutral.800',
  },
  surfaceBackgroundMediumContrastReverse: {
    default: 'primaryNeutral.700',
    // _dark: 'primaryNeutral.400',
  },
  surfaceBackgroundHighContrastReverse: {
    default: 'primaryNeutral.900',
    // _dark: 'primaryNeutral.100',
  },
  surfaceNavigationDefault: {
    default: 'secondaryNeutral.50',
    // _dark: 'primaryNeutral.800',
  },
  surfaceNavigationHover: {
    default: 'secondaryNeutral.200',
    // _dark: 'primaryNeutral.700',
  },
  surfaceNavigationActive: {
    default: 'primary.100',
    // _dark: 'primaryNeutral.600',
  },
  surfaceNavigationFocus: {
    default: 'secondaryNeutral.300',
    // _dark: 'primaryNeutral.700',
  },
  surfaceBrandDefault: {
    default: 'primary.400',
    // _dark: 'primary.300',
  },
  surfaceBrandHover: {
    default: 'primary.700',
    // _dark: 'primary.400',
  },
  surfaceBrandHoverLight: {
    default: 'primary.100',
    // _dark: 'primary.900',
  },
  surfaceBrandActive: {
    default: 'primary.900',
    // _dark: 'primary.500',
  },
  surfaceBrandActiveLight: {
    default: 'primary.200',
    // _dark: 'primary.800',
  },
  surfaceBrandDisabled: {
    default: 'primary.300',
    // _dark: 'primary.800',
  },
  surfaceDestructiveDefault: {
    default: 'danger.600',
    // _dark: 'danger.600',
  },
  surfaceDestructiveHover: {
    default: 'danger.800',
    // _dark: 'danger.800',
  },
  surfaceDestructiveHoverLight: {
    default: 'danger.50',
    // _dark: 'danger.50',
  },
  surfaceDestructiveActive: {
    default: 'danger.900',
    // _dark: 'danger.900',
  },
  surfaceDestructiveActiveLight: {
    default: 'danger.200',
    // _dark: 'danger.200',
  },
  surfaceDestructiveDisabled: {
    default: 'danger.200',
    // _dark: 'danger.200',
  },
  textHighContrast: {
    default: 'primaryNeutral.900',
    // _dark: 'primaryNeutral.50',
  },
  textMediumContrast: {
    default: 'primaryNeutral.700',
    // _dark: 'primaryNeutral.400',
  },
  textLowContrast: {
    default: 'primaryNeutral.500',
    // _dark: 'primaryNeutral.500',
  },
  textHighContrastReverse: {
    default: 'primaryNeutral.white',
    // _dark: 'primaryNeutral.900',
  },
  textLink: {
    default: 'primary.400',
    // _dark: 'primary.300',
  },
  textDestructive: {
    default: 'danger.600',
    // _dark: 'danger.200',
  },
  textBrandDefault: {
    default: 'primaryNeutral.white',
    // _dark: 'primary.900',
  },
  textBrandDisabled: {
    default: 'primary.200',
    // _dark: 'primary.500',
  },
  textBrandActive: {
    default: 'primary.600',
    // _dark: 'primary.200',
  },
  textDestructiveDefault: {
    default: 'primaryNeutral.white',
    // _dark: 'primary.900',
  },
  textDestructiveDisabled: {
    default: 'danger.50',
    // _dark: 'danger.50',
  },
  textNavigationActive: {
    default: 'primary.600',
    // _dark: 'primary.800',
  },
  borderNeutralPrimaryMediumContrast: {
    default: 'primaryNeutral.400',
    // _dark: 'primaryNeutral.700',
  },
  borderNeutralSecondaryMediumContrast: {
    default: 'secondaryNeutral.400',
    // _dark: 'primaryNeutral.700',
  },
  ...globalNavOnlySemanticColor,
}
