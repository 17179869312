// This value should sync with src/hooks/useIframeTitle.ts's value on payee-web repo
const PAGE_LOAD = 'pageLoad';
// This value should sync with src/components/IframeContainer/useUpdateUrl.ts's value on payee-web repo
const NAVIGATION = 'navigation';

const hasParentWindow = () => window.parent !== window.self;
const isForPayeeParentWindow = () => hasParentWindow() && !!window.parent.postMessage && window.ciq.payeeAppURL;
/**
 * This is for setting the dynamic height of the iframe for the npx statement page
 */
export const sendIframeSize = () => {
  const appContainer = document.querySelector('#application-root');
  if (isForPayeeParentWindow() && appContainer) {
    const height = appContainer.getBoundingClientRect().height + 100;
    window.parent.postMessage({ height, type: PAGE_LOAD }, window.ciq.payeeAppURL);
  }
};

export const sendPathname = (pathname: string) => {
  if (isForPayeeParentWindow()) {
    window.parent.postMessage({ pathname, type: NAVIGATION }, window.ciq.payeeAppURL);
  }
};

export const sendTitle = (title: string) => {
  if (isForPayeeParentWindow()) {
    window.parent.postMessage({ title, type: PAGE_LOAD }, window.ciq.payeeAppURL);
  }
};
