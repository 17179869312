import type { UseQueryOptions } from 'react-query';
import { useQuery } from 'react-query';

import type { Organization } from 'types';
import type { APIError } from 'utils/errors';

import { getRequest } from './apiRequests';

const apiURL = '/api/payee/organization';

const get = () => getRequest<null, Organization>(apiURL);

const useGetOrganizationQuery = (options: UseQueryOptions<Organization, APIError> = {}) => {
  return {
    query: useQuery<Organization, APIError>('organization', get, {
      ...options,
    }),
  };
};

export const OrganizationService = {
  useGetOrganizationQuery,
};
