import type { ComponentMultiStyleConfig } from 'quotient';

export const Modal: ComponentMultiStyleConfig = {
  parts: ['closeButton'],
  baseStyle: {
    closeButton: {
      _hover: { bg: 'primaryNeutral.100', borderRadius: '1px' },
      backgroundColor: 'primaryNeutral.white',
      border: 'none',
      borderRadius: 0,
      px: 6,
      py: 6,
      padding: 0,
      background: 'white',
    },
  },
};
