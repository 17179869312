import { Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { FC, ReactNode } from 'react';

import { Text } from 'quotient';
import { SelectedStyledSystemProps, CommonCIQProps } from 'quotient/types';

import { fontSizes, textStyles } from '../foundations/typography/typography';

export const FormLabelStyle: ComponentStyleConfig = {
  baseStyle: {
    textStyle: 'bodySemiBold',
    // adding this because font weight was not applying, need to figure out why
    fontWeight: textStyles.bodySemiBold.fontWeight,
    color: 'textHighContrast',
    marginBottom: 0,
  },
};

export const FormStyle: ComponentStyleConfig = {
  baseStyle: {
    requiredIndicator: {
      color: '#FFFFFF00',
    },
    helperText: {
      textStyle: 'bodyRegular',
      color: 'textMediumContrast',
      marginTop: 0,
    },
  },
};

export type FieldProps = {
  children?: React.ReactNode;
  id?: string;
  isRequired?: boolean;
  label: ReactNode;
  description?: string;
  showOptional?: boolean;
  isInvalid?: boolean;
  errorMessage?: string;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const Field: FC<FieldProps> = ({
  id,
  errorMessage,
  isRequired = false,
  showOptional = false,
  label,
  description,
  isInvalid,
  children,
  ...rest
}) => {
  if (isRequired && showOptional) {
    // Ideally handled via typescript
    throw new Error('Input cannot be required and optional simultaneously');
  }
  return (
    <FormControl id={id} isInvalid={isInvalid} isRequired={isRequired} {...rest}>
      <Flex flexDirection="row" justifyContent="space-between">
        <FormLabel fontSize={fontSizes.sm}>{label}</FormLabel>
        {showOptional && (
          <Text color="textMediumContrast" textStyle="bodyRegular">
            Optional
          </Text>
        )}
      </Flex>
      <FormHelperText marginBottom="4px">{description}</FormHelperText>
      {children}
      <FormErrorMessage color="textDestructive" marginTop={0} textStyle="bodyRegular">
        {errorMessage}
      </FormErrorMessage>
    </FormControl>
  );
};
