import { ComponentStyleConfig } from 'quotient';
import { fontSizes } from 'quotient/theme/foundations/typography/typography';

export const TAB_HEADER_HEIGHT = 56; // in pixels

export const TabsStyle: ComponentStyleConfig = {
  baseStyle: {
    tablist: {
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'borderNeutralPrimaryMediumContrast',
    },
    tab: {
      bg: 'surfaceBackgroundHighContrast',
      mb: '-1px',
      textStyle: 'bodyRegular',
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: 'borderNeutralPrimaryMediumContrast',
      textColor: 'textHighContrast',
      _hover: {
        boxShadow: 'inset 0 -3px var(--chakra-colors-surfaceBrandDisabled)',
        borderBottomColor: 'surfaceBrandDisabled',
      },
      _selected: {
        textStyle: 'bodySemiBold',
        boxShadow: 'inset 0 -3px var(--chakra-colors-surfaceBrandDefault)',
        borderBottomColor: 'surfaceBrandDefault',
      },
      _focus: {
        boxShadow: 'none',
      },
      _focusVisible: {
        boxShadow: 'none',
        bg: 'surfaceBackgroundMediumContrast',
      },
    },
  },
  sizes: {
    md: {
      tab: {
        fontSize: fontSizes.sm,
        paddingX: '12px',
        paddingY: '10px',
        height: `${TAB_HEADER_HEIGHT}px`,
      },
    },
  },
  defaultProps: {
    variant: 'unstyled',
  },
};
