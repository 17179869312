export type FieldErrorsType = Array<{
  type: string;
  message: string;
  param: any[];
}>;

export type ValidationError = Record<string, string[] | ValidationError[]>;

export type APIErrorType = {
  type?: string;
  message?: string;
  status?: number;
  param?: string;
  errors?: FieldErrorsType;
};

export class APIError extends Error {
  status: number;

  type?: string;

  param?: string;

  errors: FieldErrorsType | ValidationError | null | undefined;

  constructor({ type, message, status, errors, param }: APIErrorType) {
    super(message);
    this.name = this.constructor.name;
    this.status = status || 500;
    this.errors = errors;
    this.param = param;
    this.type = type;
  }
}
