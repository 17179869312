import { Global } from '@emotion/react';
import { useLocalStorageValue } from '@react-hookz/web';
import { CSSProperties, Suspense, useLayoutEffect } from 'react';

import { Center, ChakraProvider, Spinner } from 'quotient';
import theme from 'quotient/theme';

// These are the Chakra UI CSS reset styles scoped to the .chakra-scope class, which allows for applying the CSS reset
// only to the DOM subtree where Chakra UI is used. Scoping the styles to a class changes the specificity of the
// selectors so some styles are commented out to allow theme overrides to apply.
// See https://github.com/chakra-ui/chakra-ui/blob/main/packages/css-reset/src/css-reset.tsx
// See https://stackoverflow.com/a/68740042
type ResetProps = {
  fullHeight?: boolean;
};
export const ChakraScopedCSSReset = ({ fullHeight = false }: ResetProps) => (
  <Global
    styles={`
      :where(.chakra-scope) {
        line-height: 1.5;
        -webkit-text-size-adjust: 100%;
        // font-family: system-ui, sans-serif;
        // -webkit-font-smoothing: antialiased;
        // text-rendering: optimizeLegibility;
        -moz-osx-font-smoothing: grayscale;
        touch-action: manipulation;
        position: relative;
        min-height: 100%;
        font-feature-settings: 'kern';
        height: ${fullHeight ? '100%' : undefined};

        *,
        *::before,
        *::after {
          border-width: 0;
          border-style: solid;
          box-sizing: border-box;
        }
        main {
          display: block;
        }
        hr {
          border-top-width: 1px;
          box-sizing: content-box;
          height: 0;
          overflow: visible;
        }
        pre,
        code,
        kbd,
        samp {
          font-family: SFMono-Regular,  Menlo, Monaco, Consolas, monospace;
          font-size: 1em;
        }
        a {
          color: var(--chakra-colors-gray-100);
        }
        abbr[title] {
          border-bottom: none;
          text-decoration: underline;
          -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
        }
        b,
        strong {
          font-weight: bold;
        }
        small {
          font-size: 80%;
        }
        sub,
        sup {
          font-size: 75%;
          line-height: 0;
          position: relative;
          vertical-align: baseline;
        }
        sub {
          bottom: -0.25em;
        }
        sup {
          top: -0.5em;
        }
        img {
          border-style: none;
        }
        input,
        optgroup,
        select,
        textarea {
          font-family: inherit;
          font-size: 100%;
          line-height: 1.15;
          margin: 0;
        }
        input {
          overflow: visible;
        }
        select {
          text-transform: none;
        }
        button::-moz-focus-inner,
        [type="button"]::-moz-focus-inner,
        [type="reset"]::-moz-focus-inner,
        [type="submit"]::-moz-focus-inner {
          border-style: none;
          padding: 0;
        }
        fieldset {
          padding: 0.35em 0.75em 0.625em;
        }
        legend {
          box-sizing: border-box;
          color: inherit;
          display: table;
          max-width: 100%;
          padding: 0;
          white-space: normal;
        }
        progress {
          vertical-align: baseline;
        }
        textarea {
          overflow: auto;
        }
        [type="checkbox"],
        [type="radio"] {
          box-sizing: border-box;
          padding: 0;
        }
        [type="number"]::-webkit-inner-spin-button,
        [type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none !important;
        }
        input[type="number"] {
          -moz-appearance: textfield;
        }
        [type="search"] {
          -webkit-appearance: textfield;
        }
        [type="search"]::-webkit-search-decoration {
          -webkit-appearance: none !important;
        }
        input[type="search"]::-webkit-search-decoration {display: none}
        input[type="search"]::-webkit-search-cancel-button {display: none}
        input[type="search"]::-webkit-search-results-button {display: none}
        input[type="search"]::-webkit-search-results-decoration {display: none}
        input[type="search"] {
          outline-offset: 0;
        }
        ::-webkit-file-upload-button {
          -webkit-appearance: button;
          font: inherit;
        }
        details {
          display: block;
        }
        summary {
          display: list-item;
        }
        template {
          display: none;
        }
        [hidden] {
          display: none !important;
        }
        body,
        blockquote,
        dl,
        dd,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        hr,
        figure,
        p,
        pre {
          margin: 0;
        }
        fieldset {
          margin: 0;
          padding: 0;
        }
        ol,
        ul {
          margin: 0;
          padding: 0;
        }
        textarea {
          resize: vertical;
        }
        [role="button"] {
          cursor: pointer;
        }
        button::-moz-focus-inner {
          border: 0 !important;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-size: inherit;
          font-weight: inherit;
        }
        input,
        optgroup,
        select,
        textarea {
          padding: 0;
          line-height: inherit;
          color: inherit;
        }
        img,
        svg,
        video,
        canvas,
        audio,
        iframe,
        embed,
        object {
          display: block;
        }
        // img,
        // video {
        //   max-width: 100%;
        //   height: auto;
        // }
        [data-js-focus-visible] :focus:not([data-focus-visible-added]) {
          outline: none;
          box-shadow: none;
        }
        select::-ms-expand {
          display: none;
        }
      }
    `}
  />
);

type Props = {
  children: any;
  isEnabled?: boolean;
  fullHeight?: boolean;
  customStyle?: CSSProperties;
};

// TODO: Remove this logic once all ChakraWrappers are removed and there's only 1 single Chakra provider
// Everytime a ChakraProvider initializes, it resets/messes up the theme configuration
// Semantic tokens color mode listens to only html[data-theme]
// which means controlling how html[data-theme] updates is needed
export const useBlockThemeChange = (colorMode: string) => {
  const observer = new MutationObserver((mutationList) => {
    // This logic is for preventing theme flashing at the beginning of
    // page load by blocking theme changing in the beginning
    mutationList.forEach((mutation) => {
      if (
        mutation.type === 'attributes' &&
        mutation.attributeName === 'data-theme' &&
        (mutation.target as any).dataset.theme !== colorMode
      ) {
        (window.document.querySelector('html') as any).dataset.theme = colorMode;
      }
    });
  });
  return () => {
    observer.observe(window.document.querySelector('html') as any, { attributes: true });
    setTimeout(() => {
      observer.disconnect();
    }, 1000);
  };
};

export const ChakraWrapper = ({ children, isEnabled = false, fullHeight = false, customStyle }: Props) => {
  const [colorMode] = useLocalStorageValue<string>('chakra-ui-color-mode', 'light');
  const blockThemeChange = useBlockThemeChange(colorMode);
  useLayoutEffect(() => {
    blockThemeChange();
    // We only want to block theme change on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isEnabled) {
    return children;
  }
  return (
    <div className="chakra-scope" style={customStyle}>
      <ChakraProvider resetCSS={false} theme={theme}>
        <ChakraScopedCSSReset fullHeight={fullHeight} />
        <Suspense
          fallback={
            <Center h="100vh">
              <Spinner />
            </Center>
          }
        >
          {children}
        </Suspense>
      </ChakraProvider>
    </div>
  );
};
