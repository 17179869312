import React, { useState, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

import { PermissionsProvider } from 'contexts/Permissions/Permissions';
import { Center, ChakraWrapper, Spinner } from 'quotient';

import { queryClientConfig } from './configs/react-query';
import i18n from './i18n';
import { TQRouter } from './TQRouter';

type Props = {};

const App: React.VFC<Props> = () => {
  i18n.changeLanguage('en-US'); // for now until we use user locale
  const [queryClient] = useState(() => new QueryClient(queryClientConfig));
  return (
    <I18nextProvider defaultNS="translation" i18n={i18n}>
      <QueryClientProvider client={queryClient}>
        <PermissionsProvider>
          <ChakraWrapper customStyle={{ display: 'flex', flexDirection: 'column' }} fullHeight isEnabled>
            <HelmetProvider>
              <ReactQueryDevtools position="bottom-left" />
              <Suspense
                fallback={
                  <Center h="100vh">
                    <Spinner />
                  </Center>
                }
              >
                <TQRouter />
              </Suspense>
            </HelmetProvider>
          </ChakraWrapper>
        </PermissionsProvider>
      </QueryClientProvider>
    </I18nextProvider>
  );
};
export default App;
