import { CaptivateIQEvents } from '@captivateiq/events';

// combines shared code with local event constants
export class Events extends CaptivateIQEvents {
  static EVENT = {
    global: {
      button: {
        clicked: 'global__button__clicked',
      },
    },
    command_bar: {
      modal: {
        hotkey_opened: 'command_bar__modal__hotkey_opened',
        closed: 'command_bar__modal__closed',
        query_changed: 'command_bar__modal__query_changed',
        action_selected: 'command_bar__modal__action_selected',
      },
    },
  } as const;
}

export const submitEventOnClick = (buttonName?: string, onClick?: React.MouseEventHandler<HTMLButtonElement>) => (
  event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
) => {
  let buttonClickEventPayload = {};
  // falsy string values are not allowed
  if (buttonName) {
    buttonClickEventPayload = { button_name: buttonName };
  }
  Events.track(Events.EVENT.global.button.clicked, buttonClickEventPayload);

  if (onClick !== undefined) {
    onClick(event);
  }
};
