import { useMutation, UseMutationOptions, useQuery, useQueryClient } from 'react-query';

import { TQTabView } from 'components/TQDataGrid/utils/grid-types';
import { deleteRequest, getRequest, postRequest, putRequest } from 'services/apiRequests';
import type { APIList, StringifiedUUID } from 'types';
import type { TQModel } from 'types/planningCycles';
import { TQModelTreeNode } from 'types/tqModelTree';
import { GetExportFileResponse } from 'types/worksheets';
import type { APIError } from 'utils/errors';

const apiUrl = '/api/v1/tq/hierarchy-models/';

export type TQModelRequest = Pick<TQModel, 'name' | 'planning_cycle_id' | 'model_type'>;

const getTQModel = (tqModelId: StringifiedUUID) => getRequest<null, TQModel>(`${apiUrl}${tqModelId}/`);

const useGetTQModelQuery = (tqModelId: StringifiedUUID) => {
  const QUERY_KEY = ['tqModel', tqModelId];
  const queryClient = useQueryClient();

  const query = useQuery<TQModel, APIError>(QUERY_KEY, () => getTQModel(tqModelId));
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const getTQModelTree = (tqModelId: StringifiedUUID) =>
  getRequest<null, APIList<TQModelTreeNode>>(`${apiUrl}${tqModelId}/tree/`);

const useGetTQModelTreeQuery = (tqModelId: StringifiedUUID) => {
  const QUERY_KEY = ['tqModelTree', tqModelId];
  const queryClient = useQueryClient();

  const query = useQuery<APIList<TQModelTreeNode>, APIError>(QUERY_KEY, () => getTQModelTree(tqModelId));
  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return { query, invalidateQuery };
};

const createTQModel = (csrfToken: string) => (tqModel: TQModelRequest) => {
  return postRequest<TQModelRequest, TQModel>(apiUrl, tqModel, csrfToken);
};

const useCreateTQModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<TQModel, APIError, TQModelRequest> = {},
) => {
  return {
    mutation: useMutation<TQModel, APIError, TQModelRequest>(createTQModel(csrfToken), options),
  };
};

// can we consider not using strict serializer on backend here to save having to split up the payload
type UpdateTQModelProps = {
  tqModelId: StringifiedUUID;
  tqModel: TQModelRequest;
};

const updateTQModel = (csrfToken: string) => ({ tqModelId, tqModel }: UpdateTQModelProps) => {
  return putRequest<TQModelRequest, TQModel>(`${apiUrl}${tqModelId}/`, tqModel, csrfToken);
};

const useUpdateTQModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<TQModel, APIError, UpdateTQModelProps> = {},
) => {
  return {
    mutation: useMutation<TQModel, APIError, UpdateTQModelProps>(updateTQModel(csrfToken), options),
  };
};

const deleteTQModel = (csrfToken: string) => (tqModelId: StringifiedUUID) => {
  return deleteRequest<null, TQModel>(`${apiUrl}${tqModelId}/`, null, csrfToken);
};

const useDeleteTQModelMutation = (
  csrfToken: string,
  options: UseMutationOptions<TQModel, APIError, StringifiedUUID> = {},
) => {
  return {
    mutation: useMutation<TQModel, APIError, StringifiedUUID>(deleteTQModel(csrfToken), options),
  };
};

const getExportFileQuery = (tqModelId: StringifiedUUID, tabView: TQTabView, territoryId?: string) => {
  return getRequest<null, GetExportFileResponse>(
    `${apiUrl}${tqModelId}/csv_export?data_object_schema_type=${tabView}${
      territoryId ? `&territory_id=${territoryId}` : ''
    }`,
  );
};

const territoriesImportQuery = (
  tqModelId: StringifiedUUID,
  csrfToken: string,
  formData: FormData,
  territoryId?: string,
) => {
  return postRequest<{}, null>(
    `${apiUrl}${tqModelId}/csv-import/${territoryId ? `?territory_id=${territoryId}` : ''}`,
    {},
    csrfToken,
    undefined,
    {
      body: formData,
      headers: {
        Accept: 'application/json',
        'X-CSRFToken': csrfToken,
      },
    },
  );
};
export const TQModelsService = {
  apiUrl,
  useGetTQModelQuery,
  useGetTQModelTreeQuery,
  useCreateTQModelMutation,
  useUpdateTQModelMutation,
  useDeleteTQModelMutation,
  getTQModelTree,
  getExportFileQuery,
  territoriesImportQuery,
};
