// eslint-disable-next-line import/no-named-as-default
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default-member
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    load: 'all',
    backend: {
      loadPath: `${
        process.env.NODE_ENV !== 'production'
          ? process.env.TQ_HOST_URL || 'http://localhost:3003'
          : `https://%TQ_HOST_URL%`
      }/locales/{{lng}}/translation.json`,
    },
    lng: 'en-US',
    fallbackLng: 'en-US',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
