import { useMutation, UseMutationOptions } from 'react-query';

import { putRequest } from 'services/apiRequests';
import type { StringifiedUUID } from 'types';
import { DataObject, DataObjectUpdateRequest } from 'types/dataObject';
import { APIError } from 'utils/errors';

const apiUrl = (modelId: StringifiedUUID, objectId: StringifiedUUID) =>
  `/api/v1/tq/hierarchy-models/${modelId}/objects/${objectId}/`;

const updateDataObject = (modelId: StringifiedUUID, objectId: StringifiedUUID, csrfToken: string) => (
  dataObj: DataObjectUpdateRequest,
) => {
  return putRequest<DataObjectUpdateRequest, DataObject>(apiUrl(modelId, objectId), dataObj, csrfToken);
};

const useCreateDataObjectMutation = (
  modelId: StringifiedUUID,
  objectId: StringifiedUUID,
  csrfToken: string,
  options: UseMutationOptions<DataObject, APIError, DataObjectUpdateRequest> = {},
) => {
  return {
    mutation: useMutation<DataObject, APIError, DataObjectUpdateRequest>(
      updateDataObject(modelId, objectId, csrfToken),
      options,
    ),
  };
};

export const DataObjectsService = {
  useCreateDataObjectMutation,
};
