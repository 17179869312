import {
  Tooltip,
  ComponentStyleConfig,
  PlacementWithLogical,
  forwardRef,
  TooltipProps,
  ComponentWithAs,
} from '@chakra-ui/react';
import { ReactNode } from 'react';

import { SelectedStyledSystemProps } from 'quotient/types';

export const TooltipStyle: ComponentStyleConfig = {
  baseStyle: {
    bg: 'surfaceBackgroundHighContrastReverse',
    color: 'textHighContrastReverse',
    height: 'auto',
    textStyle: 'bodyRegular',
    borderRadius: '4px',
    maxWidth: '195px',
    textAlign: 'center',
    py: 1,
    px: 2,
  },
};

export type QuotientTooltipProps = {
  children: ReactNode;
  label: ReactNode;
  isDisabled?: boolean;
  ariaLabel?: string;
  placement?: PlacementWithLogical;
  hasArrow?: boolean;
  id?: string;
} & TooltipProps &
  SelectedStyledSystemProps;

export const QuotientTooltip: ComponentWithAs<'div', QuotientTooltipProps> = forwardRef<QuotientTooltipProps, 'div'>(
  ({ label, ariaLabel, placement, children, isDisabled, hasArrow = true, ...props }, ref) => {
    const _ariaLabel = ariaLabel || typeof label === 'string' ? (label as string) : undefined;
    return (
      <Tooltip
        aria-label={_ariaLabel}
        hasArrow={hasArrow}
        isDisabled={isDisabled}
        label={label}
        placement={placement}
        ref={ref}
        {...props}
      >
        {children}
      </Tooltip>
    );
  },
);
