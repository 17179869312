import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';

export const Popover: ComponentMultiStyleConfig = {
  parts: ['popper', 'content'],
  baseStyle: {
    popper: {
      // zIndex.mobileSidebar + 1
      zIndex: 1030,
    },
    content: {
      _focus: {
        outline: 0,
        boxShadow: 0,
      },
      borderColor: 'borderNeutralPrimaryMediumContrast',
    },
  },
};
