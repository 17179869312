import { Checkbox as ChakraCheckbox } from '@chakra-ui/react'; // eslint-disable-line no-restricted-imports
import { faCheck, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { forwardRef } from 'react';

import { Icon, ComponentStyleConfig } from 'quotient';
import {
  focusStyling,
  inputDefaultStyling,
  labelStyling,
  inputDisabledStyling,
  inputCheckboxDisabledStyling,
  inputCheckedStyling,
} from 'quotient/theme/common';
import { fontSizes } from 'quotient/theme/foundations/typography/typography';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const CheckboxStyle: ComponentStyleConfig = {
  baseStyle: {
    control: {
      w: '20px',
      h: '20px',
      borderRadius: '4px',
      ...inputDefaultStyling,
      _checked: {
        ...inputCheckedStyling,
        _hover: inputCheckedStyling,
        _disabled: inputCheckboxDisabledStyling,
      },
      _indeterminate: {
        ...inputCheckedStyling,
        _hover: inputCheckedStyling,
        _disabled: inputCheckboxDisabledStyling,
      },
      _disabled: inputDisabledStyling,
      _focus: focusStyling,
    },
    container: {
      m: 0,
    },
    label: {
      ...labelStyling,
      marginLeft: '8px',
    },
  },
  sizes: {
    md: {
      label: {
        fontSize: fontSizes.sm,
      },
    },
  },
  defaultProps: {
    size: null,
  },
};

export type QuotientCheckboxProps = {
  className?: string;
  children?: string | JSX.Element | null;
  isChecked?: boolean;
  isDisabled?: boolean;
  isIndeterminate?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientCheckbox = forwardRef<HTMLInputElement, QuotientCheckboxProps>(
  ({ className, children, isChecked, isDisabled, isIndeterminate, onChange, onClick, ...props }, ref) => {
    return (
      <ChakraCheckbox
        alignItems="flex-start"
        className={className}
        icon={<Icon icon={isIndeterminate ? faMinus : faCheck} />}
        isChecked={isChecked}
        isDisabled={isDisabled}
        isIndeterminate={isIndeterminate}
        ref={ref}
        onChange={onChange}
        {...props}
      >
        {children}
      </ChakraCheckbox>
    );
  },
);
