import { Field, FieldProps } from '../Field';
import { QuotientInput, QuotientInputProps } from '../Input/Input';

type InputGroupFieldProps = FieldProps & QuotientInputProps;

export const QuotientInputGroupField = ({
  id,
  label,
  description,
  isRequired,
  showOptional,
  isInvalid,
  errorMessage,
  value,
  placeholder,
  isDisabled,
  leftIcon,
  rightIcon,
  type,
  onChange,
  'data-testid': dataTestId,
  onBlur,
  ...rest
}: InputGroupFieldProps) => {
  return (
    <Field
      description={description}
      errorMessage={errorMessage}
      id={id}
      isInvalid={isInvalid}
      isRequired={isRequired}
      label={label}
      showOptional={showOptional}
      {...rest}
    >
      <QuotientInput
        data-testid={dataTestId}
        isDisabled={isDisabled}
        leftIcon={leftIcon}
        placeholder={placeholder}
        rightIcon={rightIcon}
        type={type}
        value={value}
        onBlur={onBlur}
        onChange={onChange}
      />
    </Field>
  );
};
