import React, { lazy } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { JobsAggregationContextProvider } from 'components/TQModels/context/JobsAggregationContext';
import { UsersService, OrganizationService } from 'services';

const Home = lazy(() => import('components/Home/Home'));
const NotFound = lazy(() => import('components/NotFound/NotFound'));
const ListPlanningCyclesView = lazy(() => import('components/PlanningCycles/ListPlanningCyclesView'));
const PlanningCyclesDetailView = lazy(() => import('components/PlanningCycles/PlanningCyclesDetailView'));
const TQModelView = lazy(() => import('components/TQModels/TQModelView'));

export const TQRouter: React.FC = () => {
  const {
    query: { data: currentUser },
  } = UsersService.useGetCurrentUserQuery({
    suspense: true,
    onError: () => {
      // TODO in later PR: should we just render an error state here ?
    },
  });

  const {
    // temporarily keeping this here to facilitate debugging
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    query: { data: organization },
  } = OrganizationService.useGetOrganizationQuery({
    enabled: !!currentUser,
    suspense: true,
    onError: () => {
      // TODO in later PR: should we just render an error state here ?
    },
  });
  const urlPrefix = '/tq';
  return (
    <JobsAggregationContextProvider>
      <Router>
        <Switch>
          <Route component={Home} exact path={`${urlPrefix}/`} />
          <Route component={ListPlanningCyclesView} exact path={`${urlPrefix}/planning_cycles`} />
          <Route component={TQModelView} path={`${urlPrefix}/planning_cycles/:planningCycleId/tq_models/:tqModelId`} />
          <Route component={PlanningCyclesDetailView} path={`${urlPrefix}/planning_cycles/:planningCycleId`} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </JobsAggregationContextProvider>
  );
};
