import { ComponentStyleConfig, Button as ChakraButton, Text, useStyleConfig, Flex } from '@chakra-ui/react';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import capitalize from 'lodash/capitalize';
import { forwardRef, MouseEventHandler, ReactNode } from 'react';

import { CiqQuotientLink } from 'quotient/Application/CiqQuotientLink'; // temporary
import { QuotientIcon } from 'quotient/theme/components/Icon/Icon';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';
import { submitEventOnClick } from 'quotient/utils/events';
import { getElementText } from 'quotient/utils/utils';

export const ButtonStyles: ComponentStyleConfig = {
  baseStyle: {
    whiteSpace: 'nowrap',
    borderStyle: 'solid',
    borderWidth: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'textBrandDefault',
    textStyle: 'bodySemiBold',
    _hover: {
      textDecoration: 'none',
      color: 'textBrandDefault',
    },
    _focus: {
      boxShadow: 0,
    },
    _focusVisible: {
      outlineOffset: 0,
      outlineWidth: 2,
      outlineColor: 'primary.300',
    },
    _disabled: {
      opacity: 1,
    },
  },
  variants: {
    solidBrand: {
      background: 'surfaceBrandDefault',
      _hover: {
        background: 'surfaceBrandHover',
        _disabled: {
          background: 'surfaceBrandDisabled',
          color: 'textBrandDisabled',
        },
      },
      _active: {
        background: 'surfaceBrandActive',
      },
      _disabled: {
        background: 'surfaceBrandDisabled',
        color: 'textBrandDisabled',
      },
    },
    outlineBrand: {
      background: 'transparent',
      borderColor: 'surfaceBrandDefault',
      border: '1px solid',
      color: 'surfaceBrandDefault',
      _hover: {
        background: 'surfaceBrandHoverLight',
        _disabled: {
          borderColor: 'surfaceBrandDisabled',
          background: 'transparent',
          color: 'surfaceBrandDisabled',
        },
        color: 'surfaceBrandDefault',
      },
      _active: {
        background: 'surfaceBrandActiveLight',
      },
      _disabled: {
        borderColor: 'surfaceBrandDisabled',
        background: 'transparent',
        color: 'surfaceBrandDisabled',
      },
    },
    ghostBrand: {
      background: 'transparent',
      borderColor: 'transparent',
      color: 'surfaceBrandDefault',
      _hover: {
        background: 'surfaceBrandHoverLight',
        _disabled: {
          background: 'transparent',
        },
        color: 'surfaceBrandDefault',
      },
      _active: {
        background: 'surfaceBrandActiveLight',
        _disabled: {
          background: 'transparent',
        },
      },
      _disabled: {
        color: 'surfaceBrandDisabled',
      },
    },
    linkBrand: {
      color: 'surfaceBrandDefault',
      background: 'transparent',
      width: 'fit-content',
      px: '4px',
      py: 0,
      height: 'initial',
      _hover: {
        textDecoration: 'underline',
        color: 'surfaceBrandDefault',
        _disabled: {
          textDecoration: 'none',
          color: 'surfaceBrandDisabled',
        },
      },
      _focus: {
        outline: 0,
        background: 'surfaceBrandActiveLight',
      },
      _disabled: {
        color: 'surfaceBrandDisabled',
      },
    },
    solidDestructive: {
      background: 'surfaceDestructiveDefault',
      _hover: {
        background: 'surfaceDestructiveHover',
        _disabled: {
          background: 'surfaceDestructiveDisabled',
          color: 'textDestructiveDisabled',
        },
      },
      _active: {
        background: 'surfaceDestructiveActive',
      },
      _disabled: {
        background: 'surfaceDestructiveDisabled',
        color: 'textDestructiveDisabled',
      },
    },
    outlineDestructive: {
      background: 'transparent',
      border: '1px solid',
      borderColor: 'surfaceDestructiveDefault',
      color: 'surfaceDestructiveDefault',
      _hover: {
        background: 'surfaceDestructiveHover',
        color: 'white',
        borderColor: 'surfaceDestructiveHover',
        _disabled: {
          background: 'surfaceDestructiveDisabled',
          color: 'textDestructiveDisabled',
        },
      },
      _active: {
        background: 'surfaceDestructiveActive',
      },
      _disabled: {
        background: 'surfaceDestructiveDisabled',
        color: 'textDestructiveDisabled',
      },
    },
    ghostDestructive: {},
    ghostGreen: {
      background: 'transparent',
      borderColor: 'transparent',
      color: 'green',
      _hover: {
        background: 'surfaceBrandHoverLight',
        color: 'green',
        borderColor: 'transparent',
        _disabled: {
          background: 'surfaceBrandActiveLight',
          color: 'green',
        },
      },
      _active: {
        background: 'transparent',
        _disabled: {
          background: 'transparent',
        },
      },
      _disabled: {
        background: 'surfaceBrandDisabled',
      },
    },
  },
  sizes: {
    sm: {
      px: '8px',
      py: '2px',
      height: '24px',
      borderRadius: '4px',
      textStyle: 'smallSemiBold',
      gap: '4px',
    },
    md: {
      px: '16px',
      height: '36px',
      borderRadius: '4px',
    },
  },
  defaultProps: {
    variant: 'solid',
    size: 'md',
  },
};

export const QuotientButtonVariants = ['solid', 'outline', 'ghost', 'link'] as const;
export type QuotientButtonVariant = typeof QuotientButtonVariants[number];

export const QuotientButtonSizes = ['sm', 'md'] as const;
export type QuotientButtonSize = typeof QuotientButtonSizes[number];

export type ButtonProps = {
  variant?: QuotientButtonVariant;
  size?: QuotientButtonSize;
  href?: string;
  leftIcon?: IconDefinition;
  colorScheme?: 'brand' | 'destructive' | 'green';
  rightIcon?: IconDefinition;
  keyboardShortcut?: string;
  onClick?: MouseEventHandler;
  isDisabled?: boolean;
  isLoading?: boolean;
  children: ReactNode;
  fullWidth?: boolean;
  unmask: boolean;
  buttonName?: string; // an optional unique button name which is used as the payload of the tracking event
  isActive?: boolean;
  type?: 'button' | 'submit';
  _className?: string; // Only for storybook
  /** @deprecated Don't use this props. Ask permission from Design System team. */
  sx?: Record<string, string>;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      variant = 'solid',
      size = 'md',
      colorScheme = 'brand',
      leftIcon,
      rightIcon,
      keyboardShortcut,
      isActive,
      isLoading,
      isDisabled,
      onClick,
      buttonName,
      unmask,
      fullWidth,
      _className,
      href,
      sx,
      ...rest
    }: ButtonProps,
    ref,
  ) => {
    const leftSection = leftIcon ? <QuotientIcon icon={leftIcon} /> : undefined;
    const rightSection =
      rightIcon || keyboardShortcut ? (
        <Flex alignItems="center" gap="8px" justifyContent="center">
          {keyboardShortcut ? <Text>{keyboardShortcut}</Text> : null}
          {rightIcon ? <QuotientIcon icon={rightIcon} /> : null}
        </Flex>
      ) : undefined;
    const themeVariant = variant + capitalize(colorScheme);
    if (!Object.keys(ButtonStyles.variants as object).includes(themeVariant) && window.Sentry) {
      const errorString = `${variant} and ${colorScheme} variant combination does not exist, check ButtonStyles for all available variants`;
      window.Sentry.captureMessage(errorString);
      console.error(errorString);
    }
    // TODO: Remove this ones distribution is completed
    const styles = useStyleConfig('QuotientButton', { variant: themeVariant, size });
    const contentText = getElementText(children);
    const dataText = unmask && contentText ? contentText : undefined;
    return (
      <ChakraButton
        __css={styles}
        as={href ? CiqQuotientLink : undefined}
        className={_className}
        data-text={dataText}
        href={href as string}
        iconSpacing="8px"
        isActive={isActive}
        isDisabled={isDisabled || isLoading}
        isLoading={isLoading}
        leftIcon={leftSection}
        ref={ref}
        rightIcon={rightSection}
        size={size}
        unmask={unmask}
        variant={themeVariant}
        width={fullWidth ? '100%' : 'fit-content'}
        onClick={submitEventOnClick(buttonName, onClick)}
        {...rest}
        sx={sx}
      >
        {children}
      </ChakraButton>
    );
  },
);
