import URI from 'urijs';

import type { APIErrorType } from 'utils/errors';
import { APIError } from 'utils/errors';

export const baseUrl = process.env.TQ_API_HOST ?? '';

export async function checkStatus(res: Response) {
  if (res.ok) {
    return res;
  }

  let payload: APIErrorType = {};

  try {
    const contentType = res.headers.get('content-type');
    if (contentType === 'text/html') {
      payload = {
        type: 'api_error',
        message: `${res.status} API Error`,
      };
    } else {
      payload = await res.json();
    }
  } catch (e) {
    // if we got a 500 error back, then there might not have been any JSON, so just return a
    // generic server error instead of a misleading 'Json Parsing Error'.  Callers are
    // expected to check for the 500 status code if they want to override and provide their
    // own contextually appropriate message to show to the user.
    if (res.status >= 500 && res.status <= 599) {
      payload = {
        type: 'server_error',
        message: `${res.status} Server Error`,
      };
    } else {
      payload = {
        type: 'parsing_error',
        message: `API Error: ${(e as any).toString()}`,
      };
    }
  }
  payload.status = res.status;
  throw new APIError(payload);
}

export async function getRequest<Data extends Record<string, unknown> | null, Response>(
  url: string,
  queryParams?: Data,
  opts: RequestInit = { headers: {} },
): Promise<Response> {
  let apiUrl: string | any = `${baseUrl}${url}`;
  if (queryParams) {
    apiUrl = new URI(apiUrl).addQuery(queryParams as object);
  }
  const res = await fetch(apiUrl, {
    credentials: 'include',
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      ...(opts.headers || {}),
    },
    method: 'GET',
  });
  await checkStatus(res);
  return res.json();
}

export async function postRequest<
  Data extends Record<string, unknown> | Array<Record<string, unknown>> | null,
  Response
>(url: string, data: Data, csrfToken: string, signal?: AbortSignal, opts: RequestInit = {}): Promise<Response> {
  const res = await fetch(`${baseUrl}${url}`, {
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'X-CSRFToken': csrfToken,
      ...(opts.headers ? opts.headers : {}),
    },
    method: 'POST',
    body: JSON.stringify(data),
    signal,
    ...opts,
  });
  if (res.status === 204) {
    return Promise.resolve((null as unknown) as Response);
  }
  await checkStatus(res);
  // If the response is 'text/csv, then return the response as an arrayBuffer
  if (res.headers.get('Content-Type') === 'text/csv') {
    return ((await res.arrayBuffer()) as unknown) as Promise<Response>;
  }
  return res.json();
}

export async function patchRequest<Data extends Record<string, unknown> | Array<Record<string, unknown>>, Response>(
  url: string,
  data: Data,
  csrfToken: string,
  opts: RequestInit = { headers: {} },
): Promise<Response> {
  const res = await fetch(`${baseUrl}${url}`, {
    credentials: 'include',
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'X-CSRFToken': csrfToken,
      ...(opts.headers ? opts.headers : {}),
    },
    method: 'PATCH',
    body: JSON.stringify(data),
  });
  await checkStatus(res);
  if (res.status === 204) {
    return Promise.resolve((null as unknown) as Response);
  }
  return res.json();
}

export async function putRequest<Data extends Record<string, unknown> | Array<Record<string, unknown>>, Response>(
  url: string,
  data: Data,
  csrfToken: string,
  opts: RequestInit = { headers: {} },
): Promise<Response> {
  const res = await fetch(`${baseUrl}${url}`, {
    credentials: 'include',
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'X-CSRFToken': csrfToken,
      ...(opts.headers ? opts.headers : {}),
    },
    method: 'PUT',
    body: JSON.stringify(data),
  });
  await checkStatus(res);
  return res.json();
}

export async function deleteRequest<Data extends Record<string, unknown> | null, Response>(
  url: string,
  data: Data,
  csrfToken: string,
  opts: RequestInit = { headers: {} },
): Promise<Response> {
  const res = await fetch(`${baseUrl}${url}`, {
    credentials: 'include',
    ...opts,
    headers: {
      Accept: 'application/json',
      'Content-type': 'application/json',
      'X-CSRFToken': csrfToken,
      ...(opts.headers ? opts.headers : {}),
    },
    method: 'DELETE',
    body: JSON.stringify(data),
  });
  await checkStatus(res);
  if (res.status === 204) {
    return Promise.resolve((null as unknown) as Response);
  }
  return res.json();
}
