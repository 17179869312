import { RadioGroup as ChakraRadioGroup, Radio as ChakraRadio, Flex, Text } from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';

import {
  focusStyling,
  labelStyling,
  inputDefaultStyling,
  inputDisabledStyling,
  inputCheckboxDisabledStyling,
  inputCheckedStyling,
} from 'quotient/theme/common';
import { fontSizes } from 'quotient/theme/foundations/typography/typography';
import { CommonCIQProps, SelectedStyledSystemProps } from 'quotient/types';

export const RadioStyle: ComponentStyleConfig = {
  baseStyle: {
    control: {
      ...inputDefaultStyling,
      _checked: {
        ...inputCheckedStyling,
        _before: {
          w: '45%',
          h: '45%',
        },
        _hover: inputCheckedStyling,
        _disabled: inputCheckboxDisabledStyling,
      },
      _disabled: inputDisabledStyling,
      _focus: focusStyling,
    },
    container: {
      m: 0,
    },
    label: {
      ...labelStyling,
      marginLeft: '8px',
    },
  },
  sizes: {
    md: {
      control: {
        w: '20px',
        h: '20px',
      },
      label: {
        fontSize: fontSizes.sm,
      },
    },
  },
};

type RadioValue = string | number | undefined;

type RadioItem = {
  label: string;
  value: RadioValue;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
};

export type RadioGroupProps = {
  _className?: string;
  items: RadioItem[];
  onChange: (value: string) => void;
  value: RadioValue;
  name?: string;
  isDisabled?: boolean;
} & SelectedStyledSystemProps &
  CommonCIQProps;

export const QuotientRadioGroup = ({
  _className,
  items,
  onChange,
  value,
  name,
  isDisabled,
  ...rest
}: RadioGroupProps) => {
  return (
    <ChakraRadioGroup
      className={_className}
      isDisabled={isDisabled}
      name={name}
      value={value}
      onChange={onChange}
      {...rest}
    >
      <Flex direction="column" gap="16px">
        {items.map((item) => (
          <Flex direction="column">
            <ChakraRadio
              alignItems="center"
              isDisabled={item.isDisabled}
              isRequired={item.isRequired}
              key={item.value}
              value={item.value}
            >
              {item.label}
            </ChakraRadio>
            {item.helperText && (
              <Text color="primaryNeutral.700" ml={7} textStyle="bodyRegular">
                {item.helperText}
              </Text>
            )}
          </Flex>
        ))}
      </Flex>
    </ChakraRadioGroup>
  );
};
